import {
    GET_SLIDER_IMAGE,
    ERROR_SLIDER_IMAGE
} from "../actions"

const inititalState = {
    sliderData: [],
    loading: true,
}

export const sliderReducer = (state = inititalState, action) => {

    switch (action.type) {
        case GET_SLIDER_IMAGE:
            return {
                sliderData: action.payload,
                loading: false,
            }

        case ERROR_SLIDER_IMAGE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}