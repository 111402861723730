import React from 'react'
import "./chooseUs.css"
import iso from "../../../Assets/chooseus/iso.png"
import certificate from "../../../Assets/chooseus/certificate.png"
import medal from "../../../Assets/chooseus/medal.png"
import standard from "../../../Assets/chooseus/standard.png"
import trophy from "../../../Assets/chooseus/trophy.png"

const ChooseUs = () => {
    return (
        <div className='container-fluid bg-image' >
            <h2 className='text-center pb-5 pt-3' >Why Choose Argil Group?</h2>
            <div className='row d-flex justify-content-center' >
                <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={iso} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'>ISO CERTIFIED</h6>
                </div>
                <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={certificate} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'>TISI THAILAND STANDARD</h6>
                </div>
                <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={standard} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'>SLSI SRILANKAN STANDARD </h6>
                </div>
                <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={medal} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'>HIGHEST EXPORT AWARDS</h6>
                </div>
                <div className="col-sm-2 text-center ">
                    <img className='img-fluid' width={'40%'} src={trophy} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'>HIGHEST NATIONAL AWARDS</h6>
                </div>

            </div>
        </div>
    )
}

export default ChooseUs