import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../../../../API";
import CustomLoader from "../../../customLoader/customLoader";
import CustomHeader from "../../../title";
import ImageGallery from "react-image-gallery";

export default function SpcProductInquiry(props) {
  const initialValues = {
    name: "",
    email: "",
    contact: "",
    message: "",
  };
  const navigate = useNavigate()

  const [values, setValues] = useState(initialValues);
  // const [singleData, setsingleData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // const [images, setImages] = useState([]);
  const [isActive, setIsActive] = useState("carousel-item active");
  const [nActive, setNActive] = useState("carousel-item");

  const { state } = useLocation();
  const { spcData } = state;

  useEffect(() => {
    const controler = new AbortController();

    window.scroll(0, 0);
    getSpcData(controler);
    return () => controler.abort();
  }, []);

  const [singleData, setsingleData] = useState("");

  let imageUrl = `${API.IMAGE_URL}spc/`;

  const images = [
    singleData.mainImg != null && {
      original: imageUrl + singleData.mainImg,
      thumbnail: imageUrl + singleData.mainImg,
    },

    singleData.subImg1 != null && {
      original: imageUrl + singleData.subImg1,
      thumbnail: imageUrl + singleData.subImg1,
    },

    singleData.subImg2 != null && {
      original: imageUrl + singleData.subImg2,
      thumbnail: imageUrl + singleData.subImg2,
    },
    singleData.subImg3 != null && {
      original: imageUrl + singleData.subImg3,
      thumbnail: imageUrl + singleData.subImg3,
    },

    singleData.subImg4 != null && {
      original: imageUrl + singleData.subImg4,
      thumbnail: imageUrl + singleData.subImg4,
    },

    singleData.subImg5 != null && {
      original: imageUrl + singleData.subImg5,
      thumbnail: imageUrl + singleData.subImg5,
    },
  ];

  const getSpcData = async (controler) => {
    try {
      const response = await fetch(`${API.spcProduct}/${spcData.id}`, {
        signal: controler.signal,
      });
      const getSingleData = await response.json();
      console.log("dataa", getSingleData);

      setsingleData(getSingleData);



      // if (getSingleData.mainImg != null) {
      //   setImages((prevalue) => [
      //     ...prevalue,
      //     API.IMAGE_URL + "spc/" + getSingleData.mainImg,
      //   ]);
      // }
      // if (getSingleData.subImg1 != null) {
      //   setImages((prevalue) => [
      //     ...prevalue,
      //     API.IMAGE_URL + "spc/" + getSingleData.subImg1,
      //   ]);
      // }
      // if (getSingleData.subImg2 != null) {
      //   setImages((prevalue) => [
      //     ...prevalue,
      //     API.IMAGE_URL + "spc/" + getSingleData.subImg2,
      //   ]);
      // }
      // if (getSingleData.subImg3 != null) {
      //   setImages((prevalue) => [
      //     ...prevalue,
      //     API.IMAGE_URL + "spc/" + getSingleData.subImg3,
      //   ]);
      // }
      // if (getSingleData.subImg4 != null) {
      //   setImages((prevalue) => [
      //     ...prevalue,
      //     API.IMAGE_URL + "spc/" + getSingleData.subImg4,
      //   ]);
      // }
      // if (getSingleData.subImg5 != null) {
      //   setImages((prevalue) => [
      //     ...prevalue,
      //     API.IMAGE_URL + "spc/" + getSingleData.subImg5,
      //   ]);
      // }

      setsingleData(getSingleData);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  let name, value;
  const handleInputChange = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  function handleSubmit(event) {
    event.preventDefault();
    setValues(initialValues);
    fetch(API.inquiry, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productname: spcData.names,
        name: values.name,
        email: values.email,
        contactno: values.contact,
        message: values.message,
        type: "spc",
      }),

    });

    setTimeout(() => {
      navigate('/thanks');
    }, 500);

  }

  return (
    <>
      <CustomHeader name={spcData.names} />
      <div>
        {isLoading ? (
          <CustomLoader />
        ) : (
          <>

            <div className="row my-3">
              <div className="col-md-6 ">
                <ImageGallery
                  items={images}
                  autoPlay={false}
                  thumbnailPosition="bottom"
                  additionalclassName="productInquirySlider"
                  showPlayButton={false}
                  showFullscreenButton={false}
                  disableSwipe={true}
                  lazyLoad={true}
                  disableThumbnailSwipe={true}
                />
              </div>
              <div className="col-md-6 pr-inq">

                <div className="container">
                  <div className="row">

                    <div className="col-md-12">
                      <div className="quartz-heading">
                        <i className="fas fa-clone mx-2"></i>Product Information
                      </div>
                      <div className="row">
                        <div className="col-md-7 ">
                          {/* <div className="row">
                      <div className="col-md-12 "> */}
                          <div className="QuartzInfoBox">
                            <div className="quartz-block">
                              <div className="spcsubheading">
                                Serise Name ( s ) :
                              </div>
                              <div className="spcsubheadingDes">
                                {singleData.names}
                              </div>
                            </div>
                            <div className="quartz-block">
                              <div className="spcsubheading">
                                With Enhanced Beveled Edges :
                              </div>
                              <div className="spcsubheadingDes">
                                {singleData.edges}
                              </div>
                            </div>
                            <div className="quartz-block">
                              <div className="spcsubheading">Thickness :</div>
                              <div className="spcsubheadingDes">
                                {singleData.thicknesses}
                              </div>
                            </div>
                            <div className="quartz-block">
                              <div className="spcsubheading">Click Type :</div>
                              <div className="spcsubheadingDes">
                                {singleData.clicktype}
                              </div>
                            </div>
                            <div className="quartz-block">
                              <div className="spcsubheading">Shade Variation :</div>
                              <div className="spcsubheadingDes">
                                {singleData.shadeVariation}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="QuartzInfoBox">
                            <div className="quartz-block">
                              <div className="spcsubheading">
                                Primary Color ( s ) :
                              </div>
                              <div className="spcsubheadingDes">
                                {singleData.primarycolors}
                              </div>
                            </div>
                            <div className="quartz-block">
                              <div className="spcsubheading">Backing Type :</div>
                              <div className="spcsubheadingDes">
                                {singleData.backingType}
                              </div>
                            </div>
                            <div className="quartz-block">
                              <div className="spcsubheading">Style :</div>
                              <div className="spcsubheadingDes">
                                {singleData.style}
                              </div>
                            </div>
                            <div className="quartz-block">
                              <div className="spcsubheading">Wear Layer :</div>
                              <div className="spcsubheadingDes">
                                {singleData.wearLayer}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mt-3 quartz-inquiry-heading"><i className="fas fa-clone mx-2"></i>Product Inquiry</div>
                      <div className="product-inquiry-form">
                        <div>
                          <form action="" onSubmit={handleSubmit}>
                            <div className="row input-container">
                              <div className="col-md-12">
                                <div className="styled-input wide">
                                  <input
                                    type="text"
                                    onChange={handleInputChange}
                                    value={values.name}
                                    name="name"
                                    required
                                  />
                                  <label>Name</label>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="styled-input wide">
                                  <input
                                    type="email"
                                    onChange={handleInputChange}
                                    value={values.email}
                                    name="email"
                                    required
                                  />
                                  <label>Email</label>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="styled-input wide">
                                  <input
                                    type="number"
                                    onChange={handleInputChange}
                                    value={values.contact}
                                    name="contact"
                                    required
                                  />
                                  <label>Contact Number</label>
                                </div>
                              </div>

                              <div className="col-xs-12">
                                <div className="styled-input wide">
                                  <textarea
                                    onChange={handleInputChange}
                                    value={values.message}
                                    name="message"
                                    required
                                  ></textarea>
                                  <label>Message</label>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="styled-input wide">
                                  <button
                                    type="submit"
                                    className="btn-lrg submit-btn"
                                  >
                                    Request Price Quote
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>








        )}
      </div>
    </>
  );
}
