import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { sliderReducer } from "../reducer";
import { filterWallReducer, wallTilesReducer } from "../reducer/walltiles";

const rootReducer = combineReducers({
    sliderReducer,
    wallTilesReducer,
    filterWallReducer,
})


const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;