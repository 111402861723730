const BASE_URL = "https://admin.argiltiles.com/public/api/";
export const API = {

  newsRooom: BASE_URL + "newsroom",
  wallFilter: BASE_URL + "wallfilter",
  spcProduct: BASE_URL + "spcproduct",
  slider: BASE_URL + "slider",
  qsizeMasterView: BASE_URL + "qsizematsterview",
  wallProductView: BASE_URL + "wallproductview",
  quartzProduct: BASE_URL + "quartzproduct",
  catelogue: BASE_URL + "catelogue",
  wsizematsterview: BASE_URL + "wsizematsterview",
  home: BASE_URL + "home",
  contactUs: BASE_URL + "contactus",
  inquiry: BASE_URL + "inquiry",
  size: BASE_URL + "wsizematsterview",
  finishType: BASE_URL + "finishtype",
  stock: BASE_URL + "stockview",
  designtypeview: BASE_URL + "designtypeview",
  IMAGE_URL: "https://admin.argiltiles.com/public/",
  PDF_URL: "https://admin.argiltiles.com/public/",
};
