import { ADD_NUMBER, ERROR_FILTER_WALL_PRODUCT, ERROR_GET_WALL_TILES, GET_FILTER_WALL_PRODUCT, GET_WALL_TILES, LOAD_MORE, PRODUCT_NOT_FOUND } from "../../actions/walltiles";

const initialState = {
    walltilesData: [],
    loading: true
}

// get all wall product 
export const wallTilesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_WALL_TILES:
            return {
                walltilesData: action.payload,
                loading: false
            }
        case ERROR_GET_WALL_TILES:
            return {
                ...state,
                walltilesData: [],
                loading: false
            }
        default:
            return state
    }

}
// end get all wall product 

// start wall filter product with pagination :
const filterState = {
    wallProduct: [],
    isLoading: true,
    notFound: false,
}
export const filterWallReducer = (state = filterState, action) => {
    switch (action.type) {
        case GET_FILTER_WALL_PRODUCT:
            return {
                wallProduct: [...state.wallProduct, action.payload],
                isLoading: false,
                notFound: false,
            }

        case LOAD_MORE:
            return {
                isLoading: true
            }

        case PRODUCT_NOT_FOUND:
            return {
                wallProduct: [],
                notFound: action.payload,
                isLoading: false
            }
        case ERROR_FILTER_WALL_PRODUCT:
            return {
                wallProduct: [],
                notFound: action.payload,
                isLoading: false
            }
        default:
            return state;
    }
}
// end wall filter product with pagination :
