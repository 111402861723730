import React, { useEffect, useState } from "react";
import { API } from "../../API";
import "./style.css";

const AdverTise = (props) => {
  const [viewImg, setViewImg] = useState("");
  const [view, setView] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const Controler = new AbortController();
    getAdvertise(Controler);
    return () => {
      Controler.abort();
    };
  }, []);

  const getAdvertise = (Controler) => {
    fetch(API.home, {
      signal: Controler.signal,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((resJson) => {
        setViewImg(`${API.IMAGE_URL}home_slider/${resJson[0].img}`);
        setIsLoading(true);
      })
      .catch((e) => {});
  };

  function viewAdvertise() {
    setView(false);
  }

  return (
    <>
      {isLoading && (
        <div
          className="advertiseRoot"
          style={{
            display: props.display,
          }}
        >
          <div>
            <div className="advertiseBox">
              <img className="advertiseImg" src={viewImg} alt="" />
              <i
                className="fal fa-times-circle closeAdveriseIcon"
                onClick={props.click}
              ></i>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdverTise;
