import React, { useEffect } from "react";
import CustomHeader from "../../../title";
import aboutimage from "../../../../Assets/corevalue.png";
import { Helmet } from "react-helmet";

const CoreValues = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 2;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  window.addEventListener("scroll", reveal);

  return (
    <div>
      <Helmet>
        <title>Argil ||Core Values</title>
        <link rel="canonical" href="https://www.argiltiles.com/corevalues"></link>
      </Helmet>
      <CustomHeader name="Core Value" />

      <div className="container profileContent">
        <div className="row">
          <div className="col-md-6">
            <div className="philosohy-title">
              <div className="profileHistoryTitle">Philosophy</div>
            </div>
            <div className="profileParagraph">
              Since the inception of the first company the Prajapat Tiles
              company and subsequent development in to a number of other
              manufacturing units and brands, the group has maintained a
              philosophy of Sharing and Betterment of life. In every action we
              take and all developments that we make, the above two guiding
              principles have acted as the pillars of decision making. The top
              management of the company in any and all conditions have always
              favored to these founding philosophy of Sharing and Betterment of
              life.
            </div>
            <div className="profileParagraph">
              From employees to Marketing partners to Suppliers to Customers, we
              will always make efforts that make your life better. Each and
              every step that we take we will always ensure that every stake
              holder gets a share of the result. While we will take risks, these
              stake holders will enjoy the fruits. We believe that the community
              we live in and the society we serve are the greatest reasons of
              development. Technology, growth and huge plants are but a mere
              vehicle to lead to betterment of society. We are proud that we
              have always put the society and its matters before our profits. We
              will stand by this habit always. The company started with this
              philosophy and will grow with the same.
            </div>
          </div>

          <div className="col-md-6">
            <div className="about-imgbox animateClassRight">
              {/* <img src={aboutimage} className="imageabout" alt="" /> */}
              <img src={aboutimage} className="imageabout" alt="" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="profileHistoryTitle">Mission</div>
            </div>

            <div className="profileParagraph">
              <div>
                To excel each company under ARGIL Group in their product and
                service to be at the top level.
              </div>
              <div>
                We will use the most relevant technology and inputs to make our
                products the most appreciated and demanded.
              </div>
              <div>
                We will recruit people and train them to retain them till
                retirement, always improving their Human resource Value.
              </div>
              <div>
                To consistently introduce new product range in order to meet the
                global competition and eventually attain leadership status.
              </div>
              <div>
                To invest more in the R&D department and constantly improve the
                existing product quality and increase the product line and
                product depth.
              </div>
              <div>
                To strengthen the existing export and explore the new markets.
              </div>
              <div>
                We would like to make ARGIL the most respected and admired
                business group, globally, in terms of products and services that
                add value to lives of all associated.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
