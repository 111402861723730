import { useState } from "react";
import { API } from "../../../API";



// get all product's :
export const GET_WALL_TILES = "GET_WALL_TILES";
export const ERROR_GET_WALL_TILES = "ERROR_GET_WALL_TILES";

export function getAllProducts(dispatch) {
    fetch(API.wallProductView, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
        .then((res) => {
            dispatch(getWallType(res))
        })
        .catch((e) => {
            console.log(ErrorWallType(e))
        });
}
function getWallType(val) {
    return {
        type: GET_WALL_TILES,
        payload: val
    }
}
function ErrorWallType(val) {
    return {
        type: ERROR_GET_WALL_TILES,
        payload: val
    }
}

// end get all product's :


// start wall filter product with pagination :

export const GET_FILTER_WALL_PRODUCT = "GET_FILTER_WALL_PRODUCT";
export const ERROR_FILTER_WALL_PRODUCT = "ERROR_FILTER_WALL_PRODUCT";
export const PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND";
export const LOAD_MORE = "LOAD_MORE";
export const ADD_NUMBER = "ADD_NUMBER";

let data = []
export const filterWallData = (filterDataValue, page) => (dispatch) => {
    // console.log("filterDataValue", filterDataValue)
    fetch(`${API.wallFilter}?page=${page}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            size: filterDataValue.size,
            stock: filterDataValue.stock,
            designtype: filterDataValue.designtype,
            finishtype: filterDataValue.finishtype,
        }),
    })
        .then((res) => res.json())
        .then((resJson) => {
            if (resJson.data.length === 0) {
                // setNotFound(true);
                dispatch({
                    type: PRODUCT_NOT_FOUND,
                    payload: true
                })
            } else {
                dispatch({
                    type: PRODUCT_NOT_FOUND,
                    payload: false
                })
            }
            resJson.data.forEach((element) => {
                dispatch({
                    type: GET_FILTER_WALL_PRODUCT,
                    payload: element
                })
            });
        })
        .catch((e) => {
            console.log(e);
        });
}

export const loadMoreData = () => {
    return {
        type: LOAD_MORE,
    }
}

// end wall filter product with pagination :
