import React, { useEffect } from "react";

const Exports = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return <div>Exports</div>;
};

export default Exports;
