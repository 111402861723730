import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { API } from "../../../../API";
import CustomLoader from "../../../customLoader/customLoader";
import { useNavigate } from "react-router-dom";
import CustomHeader from "../../../title";

function QuartzInquiry(props) {
  const initialValues = {
    name: "",
    email: "",
    contact: "",
    message: "",
  };

  const navigate = useNavigate();
  const [values, setValues] = useState(initialValues);
  const [singleData, setsingleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [isActive, setIsActive] = useState("carousel-item active");
  const [nActive, setNActive] = useState("carousel-item");

  const { state } = useLocation();
  const { data } = state;

  useEffect(() => {
    const controler = new AbortController();

    window.scroll(0, 0);
    getQuartzData(controler);
    return () => controler.abort();
  }, []);

  let name, value;
  const handleInputChange = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  function handleSubmit(event) {
    event.preventDefault();
    setValues(initialValues);
    fetch(API.inquiry, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productname: data.name,
        name: values.name,
        email: values.email,
        contactno: values.contact,
        message: values.message,
        type: "quartz",
      }),
    });
    setTimeout(() => {
      navigate('/thanks');
    }, 500);
  }

  const getQuartzData = async (controler) => {
    try {
      const response = await fetch(`${API.quartzProduct}/${data.id}`, {
        signal: controler.signal,
      });
      const getSingleData = await response.json();
      // console.log("dataa", getSingleData);

      if (getSingleData.mainImg != null) {
        setImages((prevalue) => [
          ...prevalue,
          API.IMAGE_URL + "quartz/" + getSingleData.mainImg,
        ]);
      }
      if (getSingleData.subImg1 != null) {
        setImages((prevalue) => [
          ...prevalue,
          API.IMAGE_URL + "quartz/" + getSingleData.subImg1,
        ]);
      }
      if (getSingleData.subImg2 != null) {
        setImages((prevalue) => [
          ...prevalue,
          API.IMAGE_URL + "quartz/" + getSingleData.subImg2,
        ]);
      }
      if (getSingleData.subImg3 != null) {
        setImages((prevalue) => [
          ...prevalue,
          API.IMAGE_URL + "quartz/" + getSingleData.subImg3,
        ]);
      }
      if (getSingleData.subImg4 != null) {
        setImages((prevalue) => [
          ...prevalue,
          API.IMAGE_URL + "quartz/" + getSingleData.subImg4,
        ]);
      }
      if (getSingleData.subImg5 != null) {
        setImages((prevalue) => [
          ...prevalue,
          API.IMAGE_URL + "quartz/" + getSingleData.subImg5,
        ]);
      }

      setsingleData(getSingleData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CustomHeader name={data.name} />
      <div>
        {isLoading ? (
          <CustomLoader />
        ) : (
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 my-4">
                  <div
                    id="carouselExampleFade"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner carouselItems">
                      {images.map((item, i) => (
                        <div key={i} className={i === 0 ? isActive : nActive}>
                          <img src={item} alt="" className="quartz-img" />
                        </div>
                      ))}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleFade"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleFade"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="quartz-heading">
                  <i className="fas fa-clone"></i> Product Information
                </div>

                <div className="col-md-4">
                  <div className="QuartzInfoBox">
                    <div className="qproduct-heading">Spaces</div>
                    <div className="quartz-block">
                      <div className="quartz-block-product">
                        <div>Primary Color :</div>
                        <div> {singleData.primarycolors}</div>
                      </div>

                      <div className="quartz-block-product">
                        <div>Stock :</div>
                        <div>{singleData.stock}</div>
                      </div>

                      <div className="quartz-block-product">
                        <div>Book Match :</div>
                        <div>{singleData.bookmatch}</div>
                      </div>

                      <div className="quartz-block-product">
                        <div>Available Finish :</div>
                        <div>{singleData.finishType}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="qproduct-heading">SIZES</div>
                  <div className="quartz-block">
                    <div className="quartz-block-product">
                      <div>Thickness :</div>
                      <div>{singleData.thicknesses}</div>
                    </div>

                    <div className="quartz-block-product">
                      <div>Slab Size :</div>
                      <div>{singleData.sizes}</div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="QuartzInfoBox">
                    <div className="quartz-block">
                      <div className="qproduct-heading">APPLICATIONS</div>
                      <div className="qsubheading">Flooring : </div>
                      <table>
                        <tbody>
                          <tr>
                            <td> Residential </td>
                            <td>
                              <i className="fas fa-check quart-icon"></i>
                            </td>
                          </tr>
                          <tr>
                            <td> Commercial </td>
                            <td>
                              <i className="fas fa-check quart-icon"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="qsubheading">Counters :</div>
                      <table>
                        <tbody>
                          <tr>
                            <td>Residential</td>
                            <td>
                              <i className="fas fa-check quart-icon"></i>
                            </td>
                          </tr>
                          <tr>
                            <td>Commercial</td>
                            <td>
                              <i className="fas fa-check quart-icon "></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="qsubheading">Wall :</div>
                      <table>
                        <tbody>
                          <tr>
                            <td>Residential</td>
                            <td>
                              <i className="fas fa-check quart-icon"></i>
                            </td>
                          </tr>
                          <tr>
                            <td>Commercial</td>
                            <td>
                              <i className="fas fa-check quart-icon"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="qsubheading">Other :</div>
                      <table>
                        <tbody>
                          <tr>
                            <td>Residential</td>
                            <td>
                              <i className="fas fa-check quart-icon"></i>
                            </td>
                          </tr>
                          <tr>
                            <td>Commercial</td>
                            <td>
                              <i
                                className="fas fa-times ms-2"
                                style={{ color: "red" }}
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 proInquiryForm">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="quartz-inquiry-heading">
                        <i className="fas fa-clone"></i> Product Inquiry
                      </div>
                      <div className="formInquiryCaption">
                        Please let me know if you have any questions.
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="product-inquiry-form">
                        <form action="" onSubmit={handleSubmit}>
                          <div className="row input-container">
                            <div className="col-md-12">
                              <div className="styled-input wide">
                                <input
                                  type="text"
                                  onChange={handleInputChange}
                                  value={values.name}
                                  name="name"
                                  required
                                />
                                <label htmlFor="">Name</label>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="styled-input wide">
                                <input
                                  type="email"
                                  onChange={handleInputChange}
                                  value={values.email}
                                  name="email"
                                  required
                                />
                                <label>Email</label>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="styled-input wide">
                                <input
                                  type="number"
                                  onChange={handleInputChange}
                                  value={values.contact}
                                  name="contact"
                                  required
                                />
                                <label>Contact Number</label>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="styled-input wide">
                                <textarea
                                  onChange={handleInputChange}
                                  value={values.message}
                                  name="message"
                                  required
                                ></textarea>
                                <label>Message</label>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <button
                                type="submit"
                                className="btn-lrg submit-btn"
                              >
                                Request Price Quote
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default QuartzInquiry;
