import React, { useEffect, useState } from "react";
import CustomHeader from "../../title";
// import dummy from "../../../Assets/dummy-tiles.webp";
import dummy from "../../../Assets/contactus.png";
// import dummy from "../../../Assets/contact/1.jpg";
import CustomLoader from "../../customLoader/customLoader";
import { API } from "../../../API";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const initialValues = {
    name: "",
    email: "",
    contact: "",
    message: "",
  };

  const [values, setValues] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    window.scroll(0, 0);
    setIsLoading(false);
  }, []);

  function getYPosition() {
    var top = window.pageYOffset || document.documentElement.scrollTop;
    return top;
  }

  let name, value;
  const handleInputChange = (e) => {
    // window.scroll({
    //   top: 1020,
    //   left: 0,
    //   behavior: "smooth",
    // });

    console.log(getYPosition());
    e.preventDefault();
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setValues({ ...values, [name]: value });
    // console.log(values);
  };


  function handleSubmit(event) {

    event.preventDefault();
    setValues(initialValues);
    fetch(API.contactUs, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: values.name,
        email: values.email,
        contactno: values.contact,
        message: values.message,
      }),
    });
    setTimeout(() => {
      navigate('/thanks');
    }, 500);
  }

  return (
    <>
      <Helmet>
        <title>Argli || Contact us</title>
        <link rel="canonical" href="https://www.argiltiles.com/contact"></link>

      </Helmet>
      <CustomHeader name="Contact Us" />
      {isLoading && <CustomLoader />}
      <div className="container">
        <div className="row contact-form-box justify-content-center">
          <div className="col-md-5 col-sm-12  p-0">
            {/* <img src={dummy} className="contactImg" alt="" /> */}
            <img src={dummy} className="img-fluid" alt="argil contact us" title="argil contact us" />
          </div>
          <div className="col-md-5 col-sm-12 ">
            <div className="contactHeading">Let's Be in Touch !!!</div>
            <div className="contactForm">
              <div className="contact-inquiry-form">
                <form action="" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className=" input-container">
                      <div className="col-md-12">
                        <div className="styled-input wide">
                          <input
                            type="text"
                            required
                            onChange={handleInputChange}
                            value={values.name}
                            name="name"
                            autoComplete="off"
                          />
                          <label>Name</label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="styled-input wide">
                          <input
                            type="email"
                            onChange={handleInputChange}
                            value={values.email}
                            name="email"
                            required
                            autoComplete="off"
                          />
                          <label>Email</label>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="styled-input wide">
                          <input
                            type="number"
                            onChange={handleInputChange}
                            value={values.contact}
                            name="contact"
                            required
                            autoComplete="off"
                          />
                          <label>Contact Number</label>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="styled-input wide">
                          <textarea
                            onChange={handleInputChange}
                            value={values.message}
                            name="message"
                            required
                            autoComplete="off"
                          ></textarea>
                          <label>Message</label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button type="submit" className="btn-lrg submit-btn">
                          Request Price Quote
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29420.731724542653!2d70.862758!3d22.817597000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8d065e671b879a69!2sMod%20Ceramic%20Industries%20Ltd.%20(%20ARGIL)!5e0!3m2!1sen!2sin!4v1645592875061!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="this is titlr"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
