import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import CustomHeader from "../../../title";

const DocumentaryFilm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>

      <Helmet>
        <title>Argil || Documentary flim</title>
        <link rel="canonical" href="https://www.argiltiles.com/documentaryfilm"></link>
      </Helmet>
      <CustomHeader name="Documentry Film" />
      <div className="container">
        <div className="documentryVideo">
          <iframe
            width="100%"
            height="400px"
            src="https://www.youtube.com/embed/OeIRz9IBs24"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div className="documentryVideo">
          <iframe
            width="100%"
            height="400px"
            src="https://www.youtube.com/embed/7I37wLtJEGU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div className="documentryVideo">
          <iframe
            width="100%"
            height="400px"
            src="https://www.youtube.com/embed/N6ogrJn0e5E"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default DocumentaryFilm;
