import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import CustomHeader from "../../../title";
import { API } from "../../../../API";
import CustomLoader from "../../../customLoader/customLoader";

export default function ProductInquiry(props) {
  const initialValues = {
    name: "",
    email: "",
    contact: "",
    message: "",
  };
  const [values, setValues] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(true);
  const { state } = useLocation();
  const { data } = state;

  const [singleData, setsingleData] = useState("");

  let imageUrl = `${API.IMAGE_URL}wall/`;

  const images = [
    singleData.mainImg != null && {
      original: imageUrl + singleData.mainImg,
      thumbnail: imageUrl + singleData.mainImg,
    },

    singleData.subImg1 != null && {
      original: imageUrl + singleData.subImg1,
      thumbnail: imageUrl + singleData.subImg1,
    },

    singleData.subImg2 != null && {
      original: imageUrl + singleData.subImg2,
      thumbnail: imageUrl + singleData.subImg2,
    },
    singleData.subImg3 != null && {
      original: imageUrl + singleData.subImg3,
      thumbnail: imageUrl + singleData.subImg3,
    },

    singleData.subImg4 != null && {
      original: imageUrl + singleData.subImg4,
      thumbnail: imageUrl + singleData.subImg4,
    },

    singleData.subImg5 != null && {
      original: imageUrl + singleData.subImg5,
      thumbnail: imageUrl + singleData.subImg5,
    },
  ];

  useEffect(() => {
    const controler = new AbortController();

    window.scroll(0, 0);
    getTilesData(controler);
    return () => controler.abort();
  }, []);

  const getTilesData = async (controler) => {
    try {
      const response = await fetch(`${API.wallProductView}/${data.id}`, {
        signal: controler.signal,
      });
      const getSingleData = await response.json();
      // console.log("dataa", getSingleData);
      setsingleData(getSingleData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  let name, value;
  const handleInputChange = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  function handleSubmit(event) {
    event.preventDefault();
    setValues(initialValues);
    fetch(API.inquiry, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productname: data.name,
        name: values.name,
        email: values.email,
        contactno: values.contact,
        message: values.message,
        type: "wall-tiles",
      }),
    });
  }

  return (
    <div>
      <CustomHeader name={data.name} />
      <div className="container">
        {isLoading ? (
          <CustomLoader />
        ) : (
          <>
            <div className="row my-3">
              <div className="col-md-6 ">
                <ImageGallery
                  items={images}
                  autoPlay={false}
                  thumbnailPosition="bottom"
                  additionalclassName="productInquirySlider"
                  showPlayButton={false}
                  showFullscreenButton={false}
                  disableSwipe={true}
                  lazyLoad={true}
                  disableThumbnailSwipe={true}
                />
              </div>
              <div className="col-md-6 pr-inq">
                {/* <div className="product-heading">
              <i className="fas fa-clone mx-2"></i>Product Description
            </div> */}
                <div className="product-details">
                  <div className="product-heading">
                    <i className="fas fa-clone mb-4"></i> Product Description
                  </div>

                  <table className="table">
                    <tbody>
                      <tr>
                        <th>Product Name </th>
                        <td>: {singleData.name}</td>
                      </tr>
                      <tr>
                        <th>Product Size </th>
                        <td>: {data.size}</td>
                      </tr>
                      <tr>
                        <th>Finish Type </th>
                        <td>: {singleData.finishType}</td>
                      </tr>
                      <tr>
                        <th>Product Stock </th>
                        <td>: {singleData.stock}</td>
                      </tr>
                      <tr>
                        <th>Design Type </th>
                        <td>: {singleData.designType}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="product-inquiry-form">
                  <div className="product-inquiry-heading">
                    <i className="fas fa-clone mt-4"></i> Product Inquiry
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="productformBox">
                      <div className="form">
                        <input
                          type="text"
                          name="name"
                          onChange={handleInputChange}
                          value={values.name}
                          required
                          autoComplete="off"
                        />
                        <label htmlFor="name" className="label-name">
                          <span className="content-name">Name</span>
                        </label>
                      </div>
                      <div className="form">
                        <input
                          type="email"
                          name="email"
                          onChange={handleInputChange}
                          value={values.email}
                          required
                          autoComplete="off"
                        />
                        <label htmlFor="name" className="label-name">
                          <span className="content-name">Email</span>
                        </label>
                      </div>

                      <div className="form">
                        <input
                          type="text"
                          onChange={handleInputChange}
                          value={values.contact}
                          name="contact"
                          required
                          autoComplete="off"
                        />
                        <label htmlFor="name" className="label-name">
                          <span className="content-name">Contact</span>
                        </label>
                      </div>
                      <div className="form">
                        <input
                          type="text"
                          onChange={handleInputChange}
                          value={values.message}
                          name="message"
                          required
                          autoComplete="off"
                        />
                        <label htmlFor="name" className="label-name">
                          <span className="content-name">Message</span>
                        </label>
                      </div>
                      {/* <input type="submit" value="Submit" className="but" /> */}
                      <div className="form">

                        <button type="submit" className="button1">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
