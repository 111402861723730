import { API } from "../../API";

export const GET_SLIDER_IMAGE = "GET_SLIDER_IMAGE";
export const ERROR_SLIDER_IMAGE = "ERROR_SLIDER_IMAGE";

export const getSlider = () => async (dispatch) => {
    try {
        const response = await fetch(API.slider);
        const data = await response.json();
        dispatch(get_sliderType(data))
    } catch (error) {
        console.log(error_sliderType(error.message))
    }
}

const get_sliderType = (val) => {
    return {
        type: GET_SLIDER_IMAGE,
        payload: val
    }
}



const error_sliderType = (error) => {
    return {
        type: ERROR_SLIDER_IMAGE,
        payload: error
    }
}