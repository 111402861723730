import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import img1 from "../../../slider/Home Slider/w2.jpg";
import img1 from "../../../Assets/home/homepage.png";
// import img1 from "../../../Assets/map/3.jpg";
import Slider from "./Slider";
import homeImg1 from "../../../Assets/home/slider1.jpg";
import homeImg2 from "../../../Assets/home/slider2.jpg";
import homeImg3 from "../../../Assets/home/slider3.jpg";
import { Helmet } from "react-helmet";
import ChooseUs from "./chooseUs";
import video from "../../../video/banner.mp4";
import enhanceSlider from "../../../Assets/enhance.jpg";
import Enhance from "./enahnce";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // for wall tiles :
  // { img: homeImg1, title: "Wall Tiles" }

  const homeData = [
    { img: homeImg2, title: "Quartz Surface" },
    { img: homeImg3, title: "SPC Products" },
  ];

  const navigate = useNavigate();
  const navigateQuartzInquery = (item) => {
    if (item.title === "Wall Tiles") {
      navigate("/walltiles");
    }
    if (item.title === "Quartz Surface") {
      navigate("/quartzsurface");
    }
    if (item.title === "SPC Products") {
      navigate("/spcproducts");
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Artificial Quartz Stone Slab Manufacturers In Morbi, Gujarat, India |
          Argil
        </title>
        <link rel="canonical" href="https://www.argiltiles.com/"></link>
        <meta
          name="descrition"
          content="Argil is an artificial quartz stone slab manufacturer in Morbi, Gujarat, India. We have the best-engineered quartz surface stone for kitchen and platform at a reasonable price. We have 10+ yrs of service. Get a price quote "
        ></meta>
        {/* OG Meta Tags */}

        <meta
          property="og:title"
          content="Artificial Quartz Stone Slab Manufacturers In Morbi, Gujarat, India | Argil"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Argiltiles" />
        <meta property="og:url" content="https://www.argiltiles.com/" />
        <meta
          property="og:description"
          content="Argil is an artificial quartz stone slab manufacturer in Morbi, Gujarat, India. We have the best-engineered quartz surface stone for kitchen and platform at a reasonable price. We have 10+ yrs of service. Get a price quote "
        />
        <meta property="og:image" content="Image URL" />

        {/* Twitter Meta Tags  */}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@argiltiles" />
        <meta property="twitter:url" content="https://www.argiltiles.com/" />
        <meta
          name="twitter:title"
          content="Artificial Quartz Stone Slab Manufacturers In Morbi, Gujarat, India | Argil"
        />
        <meta
          name="twitter:description"
          content="Argil is an artificial quartz stone slab manufacturer in Morbi, Gujarat, India. We have the best-engineered quartz surface stone for kitchen and platform at a reasonable price. We have 10+ yrs of service. Get a price quote "
        />
        <meta name="twitter:image" content="Image URL" />
      </Helmet>

      <body>
        {/* <Slider /> */}

        <div>
          <video width="100%" height="50%" autoPlay muted loop>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="container text-white">
          <div className="row">
            <div className="col-md-6">
              <div className="homeAdjust">
                <h1 className="home-heading">
                  Artificial Quartz Stone Slab Manufacturers
                </h1>
                <div className="content">
                  Argil, where we have travelled from Tradition to technology,
                  we feel the journey is growing longer and better with every
                  passing day.
                </div>

                <div className="content">
                  We have always respected our traditions and culture and have
                  whole heartedly embraced technology to take us forward without
                  any exceptions. May this journey be never ending and always
                  challenging us to soar greater height of success and
                  achievement.
                </div>
              </div>
            </div>

            <div className="col-md-6">
              {/* <img className="home-img" src={img1} alt="" /> */}
              <img
                className="img-fluid"
                src={img1}
                alt="argil about"
                title="argil about"
              />
            </div>
          </div>
        </div>

        <div className="container text-white">
          <div className="row">
            <div className="col-md-12">
              <div className="content">
                <p>
                  The management of Argil has always accomplished the big goals
                  set out by us together. Unarguably, they have done it with
                  ethics and moral of our community. Throughout their journey
                  they have upheld the principles of sharing the growth with all
                  stakeholders, leaving faces smiling and hearts warm with
                  affection and respect for the brand.
                </p>
                <p>
                  I would like to congratulate you on the same and motivate you
                  to always be this humble and serving to your brand and people
                  associated.{" "}
                  <span onClick={() => navigate("/about")}>... MORE </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* custom Products */}

        <ChooseUs />

        <div className="container">
          {/* <div
          className="home-product-heading"
          onClick={navigateQuartzInquery}>
          
          India's Best Quartz Surface and SPC Products Suppliers
        </div> */}
          <div className="row home-heading justify-content-center" style={{}}>
            India's Best Quartz Surface and SPC Products Suppliers
          </div>

          <div className="row justify-content-center">
            <>
              {homeData.map((item, ind) => (
                <div className="col-md-6 col-lg-4" key={ind}>
                  <div
                    className="wallItemsImagBox homeTilesProducts"
                    onClick={() => navigateQuartzInquery(item)}
                  >
                    <div className="hoverHomeImagesQuartz">
                      <div className="iconAlignMent">
                        <div className="imageTitle">
                          <span className="btn mt-4 btn-lrg submit-btn">
                            GET A PRICE QUOTE
                          </span>
                        </div>
                      </div>
                    </div>
                    <img
                      src={item.img}
                      alt="argil quartz , spc product"
                      title="argil quartz , spc product"
                      className="homeItemsImages"
                    />

                    <div className="productMobileView">
                      <div className="imageTitle">
                        <span className="btn btn-lrg submit-btn mt-4">
                          GET A PRICE QUOTE
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </div>
        </div>
        <Enhance />
        {/* <div className="enhance-bg">
          <div className="enhance-text">
            Enhancing the usability
          </div>
          <div className='row d-flex justify-content-center' >
            <div className="col-sm-2 text-center">
              <img className='img-fluid' width={'40%'} src={img1} alt="" srcset="" />
              <h6 className='py-4 text-center text-white'>ISO CERTIFIED</h6>
            </div>
          </div>
          <img src={enhanceSlider} style={{ width: '100%' }} alt="enhancing the usability" />
        </div> */}
      </body>
    </>
  );
};
export default Home;
