import React from 'react'
import "./chooseUs.css"

import bacteria from "../../../Assets/enhance/bacteria_free.png"
import environemnt from "../../../Assets/enhance/environment.png"
import longer from "../../../Assets/enhance/longer.png"
import more_durable from "../../../Assets/enhance/more_durable.png"
import more_uniform from "../../../Assets/enhance/more_uniform.png"
import required_less from "../../../Assets/enhance/required_less.png"




const Enhance = () => {
    return (
        <div className='container-fluid bg-image-enhance' >
            <h2 className='text-center pb-5 pt-3' >Enhancing the usability</h2>
            <div className='row d-flex justify-content-center' >
                <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={more_durable} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'><div>More</div>Durable</h6>
                </div>
                <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={more_uniform} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'><div>More</div>Uniform</h6>
                </div>
                <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={bacteria} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'><div>Bacteria-</div>Free</h6>
                </div>
                <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={environemnt} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'><div>Friendiler to the</div>Environment</h6>
                </div>
                <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={required_less} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'><div>Requires less</div>Maintenance</h6>
                </div>
                <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={longer} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'><div>Longer</div>Warranty</h6>
                </div>

                {/* <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={certificate} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'>TISI THAILAND STANDARD</h6>
                </div>
                <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={standard} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'>SLSI SRILANKAN STANDARD </h6>
                </div>
                <div className="col-sm-2 text-center">
                    <img className='img-fluid' width={'40%'} src={medal} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'>HIGHEST EXPORT AWARDS</h6>
                </div>
                <div className="col-sm-2 text-center ">
                    <img className='img-fluid' width={'40%'} src={trophy} alt="" srcset="" />
                    <h6 className='py-4 text-center text-white'>HIGHEST NATIONAL AWARDS</h6>
                </div> */}

            </div>
        </div>
    )
}

export default Enhance