import React, { useEffect } from "react";
import CustomHeader from "../../../title";
import "../style.css";
import aboutimage from "../../../../Assets/profile.png";
import { Helmet } from "react-helmet";

const Profile = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 2;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  window.addEventListener("scroll", reveal);
  return (

    <div>

      <Helmet>
        <title>Argil || Profile</title>
        <link rel="canonical" href="https://www.argiltiles.com/profile"></link>
      </Helmet>
      <CustomHeader name="Profile" />
      <div className="container profileContent">
        <div className="row">
          <div className="col-md-6">
            <div className="profileParagraph">
              Argil, where we have travelled from Tradition to technology, we
              feel the journey is growing longer and better with every passing
              day. We have always respected our traditions and culture and have
              whole heartedly embraced technology to take us forward without any
              exceptions. May this journey be never ending and always
              challenging us to soar greater height of success and achievement.
              The management of Argil has always accomplished the big goals set
              out by us together. Unarguably, they have done it with ethics and
              moral of our community. Throughout their journey they have upheld
              the principles of sharing the growth with all stakeholders,
              leaving faces smiling and hearts warm with affection and respect
              for the brand. I would like to congratulate you on the same and
              motivate you to always be this humble and serving to your brand
              and people associated.
            </div>
          </div>
          <div className="col-md-6">
            <div className="about-imgbox animateClassRight">
              {/* <img src={aboutimage} className="imageabout" alt="" /> */}
              <img src={aboutimage} className="imageabout" alt="argil profile" title="argil profile" />
            </div>
          </div>

          <div className="col-md-12">
            <div>
              <div className="profileCaption">
                Success is a never ending journey
              </div>
            </div>

            <div className="profileParagraph">
              The employees of the company have learnt the culture of sharing
              and growing from the management. We pride ourselves to be one of
              the most employee friendly brands. The employees work hard
              ceaselessly to achieve what we set forth as goals. Their efforts
              are always in alignment with the philosophy of the company. The
              customers of our brands though demanding are very loyal, within
              their heart and minds, ARGIL has established a sure shot spot.
              They always demand better products, smarter functionality and
              exquisite designs to make ARGIL forefront in the market. This is
              the biggest remuneration they have given us. I thank them for
              always helping us keep on our toes and never be complacent.
            </div>

            <div className="profileParagraph">
              The channel partners of ARGIL, retailers, distributors and
              dealers, have always had faith in the company and the management.
              Every time we have introduced a new sub brand or a product range
              they have been eagerly waiting to introduce and promote the same
              to the customers and prospects. They have maintained good
              relations with the influencers in their region to instill
              confidence and faith in the brand. lt is a matterofhonorto have
              such partners in business. This year, 2016, we have set a new goal
              and a long term vision for the ARGIL GROUP. I am confident that we
              will achieve it as always. Let us all remember, "We are agents of
              betterment, and betterment of life is our sole purpose."
            </div>

            <div>
              <div className="profileHistoryTitle">History</div>
            </div>

            <div className="profileParagraph">
              India's greatest entrepreneurial stories are all epics within
              themselves. One of those is the story of the Argil Group of
              companies. Shri Motbhai Prajapati was a potter at Sultanpur, a
              very small village. He was blessed with a son, who was destined to
              create history in the region. We know him now as Shri Amarshibhai
              Prajapati. Since a young age, he was helping his father in the
              work of pottery where he attained technical expertise in the
              workings of clay. His father shifted to Morbi employed at
              Parshuram Pottery. The family and the young boy too moved to the
              place where he would later set up one of the most admired ceramic
              brands of the world. Shri Amarshibhai too worked in the same
              factory for 8 to 10 years working in different roles, ranging from
              a manufacture of glazed jars to plaster turning moulder. He moved
              on to Junagadh District to serve as a moulder fro 2 years in
              Kathiawad Industries Ltd. People destined to create history are
              always blessed with astute vision of things to come and
              determination to articulate the vision into reality. Shri
              Amarshibhai was no different. While still employed he saw a heap
              of Mangalore roof tiles which were manufactured in Mangalore South
              India only. Manglaore roofing tiles' main ingredient was Black and
              Red clay, which was available in abundance in Morbi. The nearness
              to the raw material and his technical know-how of clay encouraged
              him to start his own production of roofing tiles.
            </div>

            <div className="profileParagraph">
              With the zeal of an entrepreneur, he returned to Morbi. With
              numerous trials, lot of experimentation and tremendous research,
              he could not design the best product or the system to mass
              manufacture roof tiles. While many would have given up, he was not
              the one to throw in the towel. He worked harder ever determined to
              make something big. Finally, he succeeded in developing durable
              beautiful Mangalore Tile. He also devised a very efficient
              production system to mass manufacture tiles. Trained man power was
              available in Morbi and that acted as a strong catalyst to start
              fast. Prajapat Tiles Company was formed in 1952. Funding was the
              biggest challenge as the Family had very little saved. Most of the
              initial capital was borrowed and a small manufacturing unit was
              commissioned by Shri Prajapati and his team. Being the first of
              its kind manufacturing unit in the region and a very heavy demand
              of Mangalore tiles, 100% production was sold.
            </div>

            <div className="profileParagraph reveal">
              The founder had witnessed good & bad days. He never wished his
              brothers to go through the terrible struggle hence he started
              training his brothers, technically and commercially. Each of them
              was given a separate unit to manage. In modern day management we
              would term these as profit centers and each brother a profit
              centre head. The flagship company though remained Prajapat Tiles
              Co.
            </div>

            <div className="profileParagraph reveal">
              Stability is a limiting belief for those with big dreams and an
              even greater appetite for betterment of life. The founder did not
              rest on the laurels of this achievement. In the year 1999 he
              established ARGIL brand which manufactured and supplied high
              quality wall tiles. It is one of the most admired wall tile brands
              in India today. Innovation and growth always go hand in hand. In
              the year 2010 the company ahs recently launched Quartz Stone unit.
              The first of its kind in India, it has the most vibrant product
              range in the segment. You will read about the company capacities
              and their product range in the coming sections of the profile.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
