import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/group_icon.png";
import google from "../../Assets/google-play-badge.png";
import apple from "../../Assets/apple.png";

import("./footer.css");

export default function CustomFooter() {
  const navigate = useNavigate();

  const footerNavigtion = (val) => {
    navigate(val);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const openFacebook = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const openAppPlayStore = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const openAppAppStore = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const openTwitter = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div>
      <div className="container-fluid customFooter">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-3">
              <div
                className="footerLogoBox"
                onClick={() => footerNavigtion("/")}
              >
                <img src={logo} alt="" className="footerImageLogo" />
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div>
                <div className="footerTitle">Quick Links</div>
                <div className="footerLinkBox">
                  <div onClick={() => footerNavigtion("/profile")}>
                    Corporate
                  </div>
                  <div onClick={() => footerNavigtion("/quartzsurface")}>
                    Products
                  </div>
                  <div onClick={() => footerNavigtion("/catalogue")}>
                    Catalogue
                  </div>
                  <div onClick={() => footerNavigtion("/contact")}>Contact</div>
                  <div onClick={() => footerNavigtion("/privacyPolicy")}>
                    Privacy
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div>
                <div className="footerTitle">Download Our App</div>
                <div className="appIconAlign">
                  <img
                    src={google}
                    alt=""
                    className="footerImageApp"
                    onClick={() =>
                      openAppPlayStore(
                        "https://play.google.com/store/apps/details?id=com.argil.tiles"
                      )
                    }
                  />
                  <img
                    src={apple}
                    alt=""
                    className="footerImageApp"
                    onClick={() =>
                      openAppAppStore(
                        "https://apps.apple.com/in/app/argil-ceramics/id1516670522"
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div>
                <div className="footerTitle">Address</div>
                <div>
                  <div className="addressFooter">
                    <div>
                      <i className=" fa fa-map-marker-alt"></i> Mod Ceramic
                      Industries Ltd.
                    </div>
                    <div>8-A, National Highway,</div>
                    <div>Morbi (Gujarat),</div>
                    <div>India 363 642</div>
                  </div>
                  <div className="addressFooter">
                    <div>
                      <i className="fas fa-phone-alt"></i>
                      <a href="tel:02822240628" style={{ color: "#2f2f2f" }}>
                        +91 2822 240628
                      </a>{" "}
                      , <br />
                      <a href="tel:02822240629" style={{ color: "#2f2f2f" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91 2822 240629
                      </a>
                    </div>
                  </div>
                  <div className="addressFooter">
                    <div>
                      <i className="fas fa-envelope"></i>
                      <a
                        href="mailto:info@argiltiles.com"
                        style={{ color: "#2f2f2f" }}
                      >
                        {" "}
                        info@argiltiles.com
                      </a>
                    </div>
                  </div>
                  <div className="mediaIcon addressFooter">
                    <i
                      className="fab fa-facebook-f"
                      onClick={() =>
                        openFacebook("https://www.facebook.com/argilgroup/")
                      }
                    ></i>
                    <i
                      className="fab fa-instagram"
                      onClick={() =>
                        openTwitter("https://www.instagram.com/argilgroup/")
                      }
                    ></i>
                    <i
                      className="fab fa-linkedin"
                      onClick={() =>
                        openTwitter(
                          "https://www.linkedin.com/company/argilgroup/?viewAsMember=true"
                        )
                      }
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container footerEnd">
        <div> &#169;2023 - Mod Ceramic Industries Ltd.</div>
        <div>
          <div
            className="footer-class-link"
            onClick={() => openInNewTab("https://perfettosolutions.in/")}
          >
            Powered By: Perfetto Solutions
          </div>
        </div>
      </div>

      {/*  */}

      <div className="freeze-footer">
        <a href="/contact" className="foot-btn btn1">
          GET QUOTE
        </a>
        <a href="tel:+919925511465" className="foot-btn btn2">
          <i
            style={{ transform: "rotate(90deg)" }}
            className="fas fa-phone headericon"
            aria-hidden="true"
          ></i>
        </a>
        <a href="https://wa.me/9925511465" className="foot-btn btn3">
          <i
            style={{
              fontSize: 22,
            }}
            className="fab fa-whatsapp headericon"
            aria-hidden="true"
          ></i>
        </a>
      </div>
    </div>
  );
}
