import React, { useEffect, useState } from "react";
import CustomHeader from "../../title";
// import dummy from "../../../Assets/dummy-tiles.webp";
import dummy from "../../../Assets/contactus.png";
// import dummy from "../../../Assets/contact/1.jpg";
import CustomLoader from "../../customLoader/customLoader";
import { API } from "../../../API";
import { Helmet } from "react-helmet";

const Thanks = () => {
    const initialValues = {
        name: "",
        email: "",
        contact: "",
        message: "",
    };

    const [values, setValues] = useState(initialValues);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scroll(0, 0);
        setIsLoading(false);
    }, []);

    function getYPosition() {
        var top = window.pageYOffset || document.documentElement.scrollTop;
        return top;
    }

    let name, value;
    const handleInputChange = (e) => {
        // window.scroll({
        //   top: 1020,
        //   left: 0,
        //   behavior: "smooth",
        // });

        console.log(getYPosition());
        e.preventDefault();
        // console.log(e);
        name = e.target.name;
        value = e.target.value;

        setValues({ ...values, [name]: value });
    };

    function handleSubmit(event) {
        event.preventDefault();
        setValues(initialValues);
        fetch(API.contactUs, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: initialValues.name,
                email: initialValues.email,
                contactno: initialValues.contact,
                message: initialValues.message,
            }),
        });
    }

    return (
        <>
            <Helmet>
                <title>Argli || Thank you</title>
            </Helmet>
            <CustomHeader name="Thank You" />
            {isLoading && <CustomLoader />}
            <div className="container">
                <div className="row contact-form-box">
                    <h6 className="display-3 text-center" style={{ height: '100px', color: '#CAC3BB' }}>Thank you </h6>
                    <span style={{ color: '#CAC3BB' }}>
                        Thank you for expressing your interest in our scheme. We will get back to you soon. Meanwhile, you can contact us on <a href="tel:+912822240628" style={{ color: '#CAC3BB' }}> +91 2822 240628/29</a> or email us at <a href="mailto:info@argiltiles.com" style={{ color: '#CAC3BB' }}>info@argiltiles.com</a> if you have any queries.
                    </span>
                </div>


            </div>
        </>
    );
};

export default Thanks;
