import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";

const NotFound = (props) => {
  const navigate = useNavigate();
  const prevPage = () => {
    navigate("/");
  };

  useEffect(() => {
    window.scroll(0, 0);
  });

  return (
    <>
      <div className="notFoundBox">
        <div>{props.name}</div>
        {props.isVisible === true ? (
          <p onClick={() => prevPage()}>Redirect To Home</p>
        ) : null}
      </div>
    </>
  );
};

export default NotFound;
