import React, { useEffect } from "react";
import CustomHeader from "../../../title";
import iso from "../../../../Assets/iso.jpeg";
import tisi from "../../../../Assets/tisi.jpeg";
import slsi from "../../../../Assets/slsi.jpeg";
import { Helmet } from "react-helmet";

const Quality = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>

      <Helmet>
        <title>Argil || Quality</title>
        <link rel="canonical" href="https://www.argiltiles.com/quality"></link>

      </Helmet>
      <CustomHeader name="Quality" />
      <div className="container profileContent">
        <div className="row">
          <div className="col-md-12">
            <div className="profileParagraph">
              In the past 65 odd years of working in semi professional and
              professional environment, we have observed that quality is not
              limited to products, but to all activities that we do. From
              smallest of the tasks like keying in an invoice data to closing
              the biggest deal, quality is required at every stage and at all
              levels. We have some quality and standard certificates.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="CorporateImageAlignMent">
              <img src={iso} alt="" />
              <div className="profileParagraph">ISO CERTIFIED</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="CorporateImageAlignMent">
              <img src={tisi} alt="" />
              <div className="profileParagraph">TISI THAILAND STANDARD</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="CorporateImageAlignMent">
              <img src={slsi} alt="" />
              <div className="profileParagraph">SLSI SRILANKAN STANDARD</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quality;
