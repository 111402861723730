import React, { useEffect } from "react";
import CustomHeader from "../../../title";
import aboutimage from "../../../../Assets/groupcompany.png";
import { Helmet } from "react-helmet";

const GroupCompany = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 2;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  window.addEventListener("scroll", reveal);

  return (
    <div>
      <Helmet>
        <title>Argil || Group Company</title>
        <link rel="canonical" href="https://www.argiltiles.com/groupcompany"></link>

      </Helmet>
      <CustomHeader name="Group Company" />
      <div className="container profileContent">
        <div className="row">
          <div className="col-md-6">
            <div className="profileParagraph">
              The company has three manufacturing units. The Roof Tile, Wall
              tiles and the Quartz Stone Unit. All the units have a very strong
              and industrious infrastructure that is instrumental in making the
              company and the brand, leading players in their respective product
              lines across India and overseas. Located at Morbi, it touches the
              National Highway leading to important port destination as well as
              the National Road Network. The locational advantage thus is
              immense as regards raw material availability as well as finished
              goods shipping and transport. Production And Technology Production
              is the most important function of any unit, the same holds true of
              ARGIL. The company has a very well managed and systematic
              production system. Under the certification of ISO 9000: 2001, SLSI
              and CE, the systems operate flawlessly to churn out continuous
              high quality production. "Production is a sum total of three major
              Factors, Men Machines and Materials."
            </div>
          </div>
          <div className="col-md-6">
            <div className="about-imgbox animateClassRight">
              {/* <img src={aboutimage} className="imageabout" alt="" /> */}
              <img src={aboutimage} className="imageabout" alt="" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="profileHistoryTitle">MEN</div>
              <div className="profileCaption">
                "Hands that work are never dirty"
              </div>
            </div>
            <div className="profileParagraph">
              The operatives of the company, many of them working since more
              than 20 years, are all a part of the success story of ARGIL. They
              are well treated and trained to always improve their productivity
              and hence their income as well. They put their best efforts with
              quality perspective so that the output is acceptable and rejection
              is low. They are well gelled with the supervisors and the middle
              management. The top management being approachable to them makes
              them all the more happy to work atARGIL. In alignment with the
              company principles, they have a very good work life balance.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="">
              <div className="profileHistoryTitle">Machines</div>
              <div className="profileCaption">
                "Leveraging Human Effort through Efficient use of Technology"
              </div>
            </div>
            <div className="profileParagraph ">
              Machines make work easy, fast, accurate and stress free for the
              humans. When machines are adopted from best supplier and leaders
              in technology, the output is always the best. ARGIL has always
              preferred to go for the best in all inputs. Technology is their
              single most scaled investment avenue. The company has the latest
              and the most modern technology adoption practice. The
              technological Strength of the group is very well highlighted in
              the profile of each plant.
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-12">
            <div>
              <div className="profileHistoryTitle">Prajapat Tiles</div>
              <div className="profileCaption">Commencement : 1951</div>
            </div>
            <div className="profileParagraph">
              Being the pioneering effort of the group, this is the most loved
              of all the production units of the founders. It is the
              manufacturer and supplier of CHAKRA brand of roof tiles. CHAKRA is
              the most famous roof tiles brand in India. One of the oldest and
              richest in tradition, it has established and maintained its
              leadership status in the roof tiles segment.With more than 50
              shapes and sizes to boast of, it embellishes roofs of thousands of
              homes around India. Keeping the homes cool and protected as well
              as doing it the natural way, the CHAKRAbrand roof tiles have
              entered Indian hearts as well.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="profileHistoryTitle">
                Argil Tiles : Mod Ceramic Industries Ltd.
              </div>
              <div className="profileCaption">Commencement: 1991</div>
            </div>
            <div className="profileParagraph">
              The wall tiles unit started in 1991 and was the first plant in the
              morbi region is spread in 50,000 Sq. Mts. and has all the
              facilities of the most modern plant having a capacity of
              manufacturing 2,40,000 sq.mts per month. With ample material
              movement space, storage facilities and productive machines laid
              out in a systematic and organized manner, the company can smoothly
              manufacture and dispatch large quantities of tiles. The men and
              Material flow is seamlessly set for most efficient operational
              management. Running as an independent unit, the infrastructure of
              the plant is upgraded regularly to keep up with the changing needs
              as well as new technology available.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="reveal">
              <div className="profileHistoryTitle">Argil Ceramics</div>
              <div className="profileCaption">Commencement: 2010</div>
            </div>
            <div className="profileParagraph reveal">
              The Quartz Stone Unit is relatively freshly set up and hence
              boasts of being more modern and advanced in comparison. It is
              established on 40,000 Sq mtr. Area, with state of the art
              technology and well-planned layout of equipments and storage areas
              as well as material and men movement sections defined, it has
              flawless production running round the clock. The inherent
              advantage of the plant being set up by professionals from day one
              is its extremely efficient and without any bottle necks of
              adjustments, making it more efficient and empowering larger
              production.
            </div>
            <div className="profileParagraph reveal">
              Quartz Stone: When the beauty of nature demands to be in every
              life technology is used to create the beauty in every life. Argil
              DuraQuartz Surface is one such product that combines the elegance
              of nature into excellent tiling solutions with the employment of
              Quartz Technology. The QUARTZ STONE unit has evolved to deliver to
              the world the best of nature in forms of stone slabs that are a
              replica of natural stones like granite and marbles. For the same
              we have used state of the art technology, thus fulfilling our own
              growth principle of Tradition to Technology. With years of
              research, lot of perseverance and huge amount of investment we
              have mastered the art of blending the aesthetics of nature, power
              of technology and human creativity into a great product-line that
              isArgil DuraQuartz Surfaces.
            </div>
            <div className="profileParagraph reveal">
              Argil DuraQuartz Surface is a high-quailty nonporous, composite
              material, built-up from hard, inorganic, polishable granulates,
              compactly bound together with a binder and a filler, coloured with
              various eco-conscious pigments. It has a smooth, stain resistant
              surface, comes in a variety of sizes and has a wide range of
              applications. An incredible mix of long lasting durability and
              breathtaking aesthetics, Argil DuraQuartz Surfaces is just the
              right choice for those who love to experience the best things in
              life. Already a global trend in innovative surface material,
              engineered quartz surface material is quickly becoming the new
              status symbol for style. Argil DuraQuartz Surfaces proudly brings
              you the global surface-fashion statement to turn your desires into
              stunning realities.
            </div>
          </div>
        </div>

        <div className="row reveal">
          <div className="col-md-12">
            <div className="profileParagraph">
              The SLABS of Quartz Stone fromARGILare a revolutionary product.
              Durable, with great surface quality and most of all machin ability
              makes them the most suitable substitute of Natural Stone. Below
              are the USP's ARGIL Duraquartz Surfaces:
            </div>

            <ul>
              <li>Scratch Resistance</li>
              <li>Stain Resistance</li>
              <li>Chip and Crack Resistance</li>
              <li>Hear and Burn Resistance</li>
              <li>Resistance to household chemicals, acids and solvents</li>
              <li>Low maintenance</li>
              <li>Non-porous</li>
              <li>High flexural strength</li>
              <li>Glossiness</li>
              <li>Color consistency</li>
              <li>Immunity to freeze and thaw</li>
            </ul>
            <div className="profileParagraph">
              They come in jumbo sizes of 3000 x 1300 mm and 3200 x 1600 mm also
              it can be customized as per requirement. They find applications in
              big table tops kitchen tops and the like. They can be resized,
              cut, beveled like any stone. Custom sizes for the stone are also
              available. The tiles can be of 300x300 mm, 300x600 mm, 600x600 mm,
              800x800 mm
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupCompany;
