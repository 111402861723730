import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { API } from "../../../../API";
import CustomLoader from "../../../customLoader/customLoader";
// import data from "../../../../data";
// import CustomButton from "../../../customButton";
import CustomHeader from "../../../title";

const SpcProducts = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const navigateLvtProductInquery = (val) => {
    navigate("/spcproductinquiry", { state: { spcData: val } });
  };

  const controler = new AbortController();
  useEffect(() => {
    window.scrollTo(0, 0);
    getSpcData(controler);
    return () => controler.abort();
  }, []);

  const getSpcData = async (controler) => {
    try {
      const response = await fetch(API.spcProduct, {
        signal: controler.signal,
      });
      const data = await response.json();
      // console.log(data);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  function refreshPage() {
    setData([]);
    getSpcData(controler);
    setIsLoading(true);
  }


  return (
    <>
      <Helmet>
        <title>SPC Flooring Tiles Manufacturers In Morbi, Gujarat, India | Argil</title>
        <link rel="canonical" href="https://www.argiltiles.com/spcproducts"></link>


        {/* OG Meta Tags */}

        <meta property="og:title" content="Quartz Stone Slab For Kitchen Platform Countertops Manufacturers In Morbi, Gujarat, India | Argil" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Argiltiles" />
        <meta property="og:url" content="https://www.argiltiles.com/spcproducts" />
        <meta property="og:description" content="Argil is SPC flooring tiles manufacturers in Morbi, Gujarat, India. We are the best SPC flooring brands with 10+ yrs of service. Get a price quote " />
        <meta property="og:image" content="Image URL" />

        {/* Twitter Meta Tags  */}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@argiltiles" />
        <meta property="twitter:url" content="https://www.argiltiles.com/spcproducts" />
        <meta name="twitter:title" content="SPC Flooring Tiles Manufacturers In Morbi, Gujarat, India | Argil" />
        <meta name="twitter:description" content="Argil is SPC flooring tiles manufacturers in Morbi, Gujarat, India. We are the best SPC flooring brands with 10+ yrs of service. Get a price quote" />
        <meta name="twitter:image" content="Image URL" />

      </Helmet>
      <div>
        <CustomHeader name="SPC Products" click={refreshPage} />
        <div className="container">
          <div className="row">
            {isLoading ? (
              <CustomLoader />
            ) : (
              <>
                <h1 className="display-6 py-4" style={{ color: '#CAC3BB ' }}>SPC Flooring Tiles </h1>
                {data.map((spcData) => {
                  let spcImage = `${API.IMAGE_URL}spc/${spcData.mainImg}`;
                  return (
                    <div className="col-lg-4 col-md-6 " key={spcData.names.toString()}>
                      <div
                        className="wallItemsImagBox"
                        onClick={() => navigateLvtProductInquery(spcData)}
                      >
                        <div className="hoverImagesQuartz">
                          <div className="iconAlignMent">
                            {/* <div className="imageTitle">{spcData.names}</div> */}
                          </div>
                        </div>
                        <img
                          src={spcImage}
                          alt=""
                          className="quartzItemsImages"
                        />
                        <div className="productMobileView">
                          {/* <div className="imageTitle">{spcData.names}</div> */}
                        </div>
                      </div>
                      <div className="imageTitle-quartz">{spcData.names}</div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="QuartzSurButtonAlign">
                {/* <div className="QuartzSurButton">
                  <CustomButton ButtonName="Next" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpcProducts;
