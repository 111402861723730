import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  useNavigate,
  Navigate,
} from "react-router-dom";

// old logo
// import argil from "../../Assets/ARGIL.png";
// import argilLogo from "../../Assets/logo_bg.png";

import argil from "../../Assets/argilgraygroup.png";
import argilLogo from "../../Assets/argilgraygroup.png";

import "./nav.css";
import $ from "jquery";
import Exports from "../Screen/Corporate/Exports/Exports";
import Plants from "../Screen/Corporate/Plants/Plants";
import Achievements from "../Screen/Corporate/Achievements/Achievements";
import Quality from "../Screen/Corporate/Quality/Quality";
import QuartzSurface from "../Screen/Products/QuartzSurface/QuartzSurface";
import GroupCompany from "../Screen/Corporate/GroupCompany/GroupCompany";
import WallTiles from "../Screen/Products/WallTiles/WallTiles";
import Catelogue from "../Screen/Catalouge/Catalogue";
import Profile from "../Screen/Corporate/Profile/Profile";
import DocumentaryFilm from "../Screen/Corporate/DocumentaryFilm/DocumentaryFilm";
import Home from "../Screen/Home/Home";
import CoreValues from "../Screen/Corporate/CoreValues/CoreValues";
import Contact from "../Screen/Contact/Contact";
// import Navbarmenu from "../menu/Navbarmenu";
import CustomFooter from "../footer/Footer";
import ProductInquiry from "../Screen/Products/productInquiry";
import PrivacyPolicy from "../Screen/privacyPolicy";
import About from "../Screen/Corporate/About/About";
import QuartzInquiry from "../Screen/Products/QuartzSurface/QuartzInquiry";
import SpcProducts from "../Screen/Products/SpcProducts/SpcProducts";
import SpcProductInquiry from "../Screen/Products/SpcProducts/SpcProductinquiry";
import NotFound from "../notFound/notFound";
import Thanks from "../Screen/Thankyou/Thanks";
import QuartzStoneLab from "../Screen/Products/QuartzSurface/QuartzStoneSlab";

class NavbarCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      colorChange: false,
    };
  }

  handleNavClick = () => {
    this.setState({ show: false });
  };

  render() {
    window.addEventListener("scroll", (event) => {
      if (window.scrollY < 100) {
        $("#navBar").removeClass("colorChange");
        $("#navBar").removeClass("colorFont");
        $("#navLogo").addClass("NavbuttonRight");
        $("#navBar").addClass("colorNavFont");
        $("#navBar").removeClass("colorNavFontAfter");
        $("#navLogoName").removeClass("NavLogoName");

        $("#btn-color").addClass("btn-call-font-default");
        $("#btn-color").removeClass("btn-call-font-default-hover");
        $("#btn-color").removeClass("btn-call-font-add");
      } else {
        $("#navBar").addClass("colorChange");
        $("#navLogoName").addClass("NavLogoName");
        $("#navBar").removeClass("colorNavFont");
        $("#navBar").addClass("colorNavFontAfter");
        $("#navBar").addClass("colorFont");
        $("#navLogo").removeClass("NavbuttonRight");

        $("#btn-color").removeClass("btn-call-font-default");
        $("#btn-color").addClass("btn-call-font-default-hover");
        $("#btn-color").addClass("btn-call-font-add");
      }
    });

    return (
      <>
        <Router>
          <nav
            className="navbar navbar-expand-lg navbar-light bg-light  fixed-top alignMent colorNavFont"
            id="navBar"
          >
            {/* <Navbarmenu /> */}
            <div className="container">
              <div id="navLogo" className="NavbuttonRight">
                <a className="navbar-brand" href="/">
                  <img src={argilLogo} className="navLogoIcon" alt="" />
                </a>
              </div>
              <div id="navLogoName">
                <a className="navbar-brand" href="/">
                  <img src={argil} className="logoNavbar" alt="" />
                </a>
              </div>
              <div>
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={() => this.setState({ show: !this.state.show })}
                >
                  <span className="dash">
                    <i className="fa solid fa-bars" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
              <div
                className={`collapse navbar-collapse  ${
                  this.state.show ? "show" : ""
                }`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-lg-0">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/"
                      onClick={this.handleNavClick}
                    >
                      Home
                    </NavLink>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Corporate
                    </a>
                    <ul
                      className="dropdown-menu DropDownNav"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <NavLink
                          className="dropdown-item"
                          onClick={this.handleNavClick}
                          to="/profile"
                        >
                          Profile
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/about"
                          onClick={this.handleNavClick}
                        >
                          About
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/documentaryfilm"
                          onClick={this.handleNavClick}
                        >
                          Documentary Film
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/corevalues"
                          onClick={this.handleNavClick}
                        >
                          Core Value
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/groupcompany"
                          onClick={this.handleNavClick}
                        >
                          Group Company
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/achievements"
                          onClick={this.handleNavClick}
                        >
                          Achievements
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/plants"
                          onClick={this.handleNavClick}
                        >
                          Plants
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/quality"
                          onClick={this.handleNavClick}
                        >
                          Quality
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Products
                    </a>
                    <ul
                      className="dropdown-menu DropDownNav"
                      aria-labelledby="navbarDropdown"
                    >
                      {/* <li>
                        <NavLink
                          className="dropdown-item"
                          onClick={this.handleNavClick}
                          to="/walltiles"
                        >
                          Wall Tiles
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink
                          className="dropdown-item"
                          onClick={this.handleNavClick}
                          to="/quartzsurface"
                        >
                          Quartz Surface
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          onClick={this.handleNavClick}
                          to="/spcproducts"
                        >
                          SPC Products
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      onClick={this.handleNavClick}
                      to="/catalogue"
                    >
                      Catalogue
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link "
                      onClick={this.handleNavClick}
                      to="/contact"
                    >
                      Contact
                    </NavLink>
                  </li>

                  <li
                    className=""
                    style={{
                      borderColor: "#CAC3BB",
                      borderWidth: 1,
                      borderStyle: "solid",
                      paddingLeft: "3%",
                    }}
                  >
                    <NavLink
                      className="nav-link"
                      onClick={this.handleNavClick}
                      to="/contact"
                    >
                      Get a Price Quote
                    </NavLink>
                  </li>

                  <button
                    type="submit"
                    className="btn btn-call-font-default"
                    id="btn-color"
                  >
                    <a href="tel:9925511465">
                      <i className="fas fa-phone-alt me-2"></i>
                      +91 99255 11465
                    </a>
                  </button>
                </ul>
              </div>
            </div>
          </nav>
          <div className="blankNavSpace"></div>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/documentaryfilm" element={<DocumentaryFilm />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/corevalues" element={<CoreValues />} />
            <Route path="/achievements" element={<Achievements />} />
            <Route path="/exports" element={<Exports />} />
            <Route path="/quality" element={<Quality />} />
            <Route path="/plants" element={<Plants />} />
            <Route path="/groupcompany" element={<GroupCompany />} />
            <Route path="/quartzsurface" element={<QuartzStoneLab />} />
            {/* <Route path="/quartz-stone-lab" element={<QuartzStoneLab />} /> */}
            {/* <Route path="/walltiles" element={<WallTiles />} /> */}
            <Route path="/catalogue" element={<Catelogue />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/quartzInquiry" element={<QuartzInquiry />} />
            <Route path="/productInquiry" element={<ProductInquiry />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/spcproducts" element={<SpcProducts />} />
            <Route path="/spcproductinquiry" element={<SpcProductInquiry />} />
            <Route path="/thanks" element={<Thanks />} />
            <Route
              path="*"
              element={<NotFound name="Page Not Found" isVisible={true} />}
            />
          </Routes>

          <CustomFooter />
        </Router>
      </>
    );
  }
}

export default NavbarCustom;
