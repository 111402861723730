import React, { useEffect } from "react";
import CustomHeader from "../../../title";
import National from "../../../../Assets/award_big.jpeg";
import Export from "../../../../Assets/achievements_thum1.jpeg";
import { Helmet } from "react-helmet";

const Achievements = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Helmet>
        <title>Argil || Achievements</title>
        <link rel="canonical" href="https://www.argiltiles.com/achievements"></link>

      </Helmet>
      <CustomHeader name="Achievements" />
      <div className="container profileContent">
        <div className="row">
          <div className="col-md-12">
            <div className="profileParagraph">
              The Founder has been in the business since last 60 years within
              which he has scaled new heights regularly. He has been recognized
              a number times and mostly by credible institutions including the
              government of India.
            </div>
          </div>
        </div>

        <div className="row CorporateSpace">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4">
                <img src={National} alt="" className="corporateNationalImage" />
              </div>
              <div className="col-md-7">
                <div className="profileCaption">
                  National Award for the year 1997 (for best quality products)
                  from vice president of India
                </div>
                <div className="profileParagraph">National Award</div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4">
                <img src={Export} alt="" className="corporateNationalImage" />
              </div>
              <div className="col-md-7">
                <div className="profileCaption">
                  Highest Export Awards, Govt. of India and State
                </div>
                <div className="profileParagraph">Export Award</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Achievements;
