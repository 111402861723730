import React, { useEffect } from "react";
import CustomHeader from "../../../title";
import aboutimage from "../../../../Assets/plants.png";
import { Helmet } from "react-helmet";

const Plants = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 2;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  window.addEventListener("scroll", reveal);

  return (
    <div>

      <Helmet>
        <title>Argil || Plants</title>
        <link rel="canonical" href="https://www.argiltiles.com/plants"></link>

      </Helmet>
      <CustomHeader name="Plants" />
      <div className="container profileContent">
        <div className="row">
          <div className="col-md-12">
            <div className="philosohy-title">
              <div className="profileHistoryTitle">Digital Wall Tiles</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="profileParagraph">
                The best quality Digital tiles are manufactured and delivered to
                the customers by ARGIL. We take pride in being the leaders in
                setting industry trends in wall tiles since last 25 years. We have
                the largest range of wall tiles in terms of sizes available as
                well as surface finish. The idea of the top management is to give
                as many options as possible to make sure our existing customers
                and newer ones relating to the brand will always get their choice
                met from our range. The sizes include 250 x 330 mm, 300 x 300 mm,
                300 x 450 mm, 200 x 600 mm, 300 x 600 mm. The larger sizes like
                200 x 600 & 300 x 600 mm are an awesome range and give a feel of
                grandiose to any wall clad with them. The size gives an impression
                of pride and power. The smaller sizes are ideal and affordable
                solution to the large middle class in India and across the world.
              </div>
              <div className="profileParagraph">
                The Finishes available in the above sizes include High gloss,
                which replicates mirror finish. Satin, the touch of silk. Rustic,
                the natural stones revisited. Wooden, bringing thick forests to
                your home. The above parameters are met by plethora of designs
                which can be seen in the product catalogs of the company. With
                Digital printing Technology on our Hands, the company has
                virtually no limit in the looks and appearances of the tiles. The
                technology has given designer the flexibility to let their
                imagination run wild and recreate any look. We have also permitted
                our bulk buyers as well as design Specialist dealing with us to
                demand whatever theme or look they desire and we promise to
                recreate it for them. All these add up to make the biggest range
                of wall tiles offering in India. The sum total of different SKUs
                is more than 1000 making this unarguably one of the most wide
                range in wall tiles manufacturers in the region.
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-imgbox animateClassRight">
                {/* <img src={aboutimage} className="imageabout" alt="" /> */}
                <img src={aboutimage} className="imageabout" alt="argil plants" title="argil plants" />
              </div>
            </div>


            <div className="profileParagraph">
              ARGIL is already in collaboration with most of the top ceramic
              machinery suppliers like Sacmi, System, B&T, Kera Jet, Modena,
              HRMEZE and many others. Rotocolor drum printing machine is the
              right machine for with random effect printing at high resolution
              to give natural look to tiles. The Press is one of the most
              important machine in tile production. Its effective use will lead
              to longer life of tiles. We have a number of imported presses
              including Sacmi 1600, Lethai 1300, Sacmi 980 and Sacmi 680. Both
              these manufacturers are leaders in Ceramic Machinery globally.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="profileHistoryTitle">DuraQuartz</div>
            </div>
            <div className="profileParagraph">
              Argil DuraQuartz Surfaces' range of products is manufactured under
              a fully-automated production process, including professional
              carving, air-drying, polishing, and scraping and thickening
              techniques. At every stage of production, we remain alert to
              maintain the high-caliber precision and top-in-class accuracy. No
              wonder, Argil DuraQuartz Surfaces' is rapidly becoming a synonym
              for style statement with utmost quality across its growing fan
              base all over India and the world.The plant is equipped with 18
              head Polishing lines which provide high gloss on the surface and
              maintains smooth finish along with flatness. The High Pressure,
              Vacuum and Vibrating press are inevitable component of production.
              They instill the toughness and durability components of the Quartz
              Stone. The Automatic Bridge cutting Machine empowers fast,
              accurate and efficient cutting and sizing of the stones.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="">
              <div className="profileHistoryTitle">
                Research and Development
              </div>
            </div>
            <div className="profileParagraph">
              Since its inception, company has been keen on innovation and
              Research. From material to production process to quality testing
              to enhancement of product performance, the company has and will
              invest in research. We have a well established research centre in
              both our units. It is well equipped with a laboratory and manned
              by experienced and expert people. The work on testing and
              improvement is done round the clock. With every testing there is
              SPC and SQC involved to keep the Production System up to date.
              Along with the regular activity, we have our researchers working
              on newer and better product performance designs of the product.
              They vary parameters of material, production and ageing to test
              and re-test new ideas. This gives birth to better and improved
              products over a period of time.The innovation index too is
              controlled by innovating in every department of the company. This
              activity is basically carried out by every individual in his or
              her area of work. The functional heads and the supervisors are
              continuously looking to increase efficiency, reduce losses and
              increase quality production.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="profileHistoryTitle reveal">
                Design Department
              </div>
            </div>
            <div className="profileParagraph reveal">
              Product design is the single most important and market generating
              parameter. Thus we have established a computerized Design
              department with fine arts experts. Both the units have their
              independent teams who work help each other when needed but take up
              their own challenges on designing of fresh looks and trendy
              designs. We have also collaborated with Spanish and Italian
              companies for the design know how.The sole role of design
              department in both the units is to roll out trendy as well as new
              coming designs that will work in the market. They have a
              consistent communication with the marketing team who give them new
              thoughts and demands prevailing in the markets. Based on these,
              the design teams churn out new designs.Few of all the designs
              proposed are accepted, as we believe in delivering the best, not
              everything. The designs are then market tested before
              commercialization. We make sure that needs of the market are
              beaten and we are always leader and never copycats. Being pioneers
              in introduction fresh styles and range of products makes us feel
              proud.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plants;
