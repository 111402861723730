import React from "react";
import "./style.css";

function CustomLoader() {
  return (
    <div className="loaderBox">
      <div className="spinner-border" role="status">
        <span className="sr-only"></span>
      </div>
    </div>
  );
}

export default CustomLoader;
