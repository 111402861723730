import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
export default class CustomHeader extends Component {
  render() {
    return (
      <div className="container-fluid titleBox">
        <div className="container">
          <NavLink to="/" className="custom-header-home">
            Home
          </NavLink>
          / <span onClick={this.props.click}
            style={{
              cursor: "pointer"
            }}
          >{this.props.name}</span>
          {/* <div> / {this.props.name}</div> */}
        </div>
      </div>
    );
  }
}
