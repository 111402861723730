import React, { useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import "./App.css";
import "./color.css";
import AdverTise from "./components/advertise";
import NavbarCustom from "./components/navbar/NavbarComp";
import About from "./components/Screen/Corporate/About/About";
import DocumentaryFilm from "./components/Screen/Corporate/DocumentaryFilm/DocumentaryFilm";

function App() {
  const [advertise, setAdvertise] = useState(true);
  const [closeAdvertise, setCloseAdvertise] = useState("block");

  let advertiseSorage = sessionStorage.getItem("getAdvertise");

  useEffect(() => {
    if (advertiseSorage === "one") {
      setCloseAdvertise("none");
    }
  });

  function advertisement() {
    sessionStorage.setItem("getAdvertise", "one");
    setAdvertise(false);
  }

  return (
    <>
      {advertise && (
        <AdverTise display={closeAdvertise} click={() => advertisement()} />
      )}

      <NavbarCustom />
      <script src="/build/client.entry.js" />
    </>
  );
}

export default App;
