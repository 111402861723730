import React, { useEffect } from "react";
import CustomHeader from "../../title";
import "./style.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <CustomHeader name="Privacy Policy" />
      <div className="container profileContent">
        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="profileCaption mt-5">Privacy Policy</div>
            </div>
            <div className="profileParagraph">
              Mahadev built the Argil Ceramics app as a Free app. This SERVICE
              is provided by Mahadev at no cost and is intended for use as is.
              This page is used to inform visitors regarding my policies with
              the collection, use, and disclosure of Personal Information if
              anyone decided to use my Service. If you choose to use my Service,
              then you agree to the collection and use of information in
              relation to this policy. The Personal Information that I collect
              is used for providing and improving the Service. I will not use or
              share your information with anyone except as described in this
              Privacy Policy. The terms used in this Privacy Policy have the
              same meanings as in our Terms and Conditions, which is accessible
              at Argil Ceramics unless otherwise defined in this Privacy Policy.
            </div>
          </div>

          <div className="col-md-12">
            <div>
              <div className="profileCaption">
                Information Collection and Use
              </div>
            </div>
            <div className="profileParagraph">
              For a better experience, while using our Service, I may require
              you to provide us with certain personally identifiable
              information, including but not limited to Wall Tiles, Quartz,
              Quartz Surface, Ceramics. The information that I request will be
              retained on your device and is not collected by me in any way. The
              app does use third party services that may collect information
              used to identify you. Link to privacy policy of third party
              service providers used by the app
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <div className="profileCaption">Log Data</div>
            </div>
            <div className="profileParagraph">
              I want to inform you that whenever you use my Service, in a case
              of an error in the app I collect data and information (through
              third party products) on your phone called Log Data. This Log Data
              may include information such as your device Internet Protocol
              (“IP”) address, device name, operating system version, the
              configuration of the app when utilizing my Service, the time and
              date of your use of the Service, and other statistics.
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <div className="profileCaption">Cookies</div>
            </div>
            <div className="profileParagraph">
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory. This Service does not use these
              “cookies” explicitly. However, the app may use third party code
              and libraries that use “cookies” to collect information and
              improve their services. You have the option to either accept or
              refuse these cookies and know when a cookie is being sent to your
              device. If you choose to refuse our cookies, you may not be able
              to use some portions of this Service.
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <div className="profileCaption">Service Providers</div>
            </div>
            <div className="profileParagraph">
              I may employ third-party companies and individuals due to the
              following reasons: To facilitate our Service;To provide the
              Service on our behalf;To perform Service-related services; orTo
              assist us in analyzing how our Service is used. I want to inform
              users of this Service that these third parties have access to your
              Personal Information. The reason is to perform the tasks assigned
              to them on our behalf. However, they are obligated not to disclose
              or use the information for any other purpose.
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <div className="profileCaption">Security</div>
            </div>
            <div className="profileParagraph">
              I value your trust in providing us your Personal Information, thus
              we are striving to use commercially acceptable means of protecting
              it. But remember that no method of transmission over the internet,
              or method of electronic storage is 100% secure and reliable, and I
              cannot guarantee its absolute security.
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <div className="profileCaption">Links to Other Sites</div>
            </div>
            <div className="profileParagraph">
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by me. Therefore, I h3ly
              advise you to review the Privacy Policy of these websites. I have
              no control over and assume no responsibility for the content,
              privacy policies, or practices of any third-party sites or
              services.
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <div className="profileCaption">Children’s Privacy</div>
            </div>
            <div className="profileParagraph">
              These Services do not address anyone under the age of 13. I do not
              knowingly collect personally identifiable information from
              children under 13. In the case I discover that a child under 13
              has provided me with personal information, I immediately delete
              this from our servers. If you are a parent or guardian and you are
              aware that your child has provided us with personal information,
              please contact me so that I will be able to do necessary actions.
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <div className="profileCaption">
                Changes to This Privacy Policy
              </div>
            </div>
            <div className="profileParagraph">
              I may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. I will
              notify you of any changes by posting the new Privacy Policy on
              this page. This policy is effective as of 2020-06-15
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <div className="profileCaption">Contact Us</div>
            </div>
            <div className="profileParagraph">
              If you have any questions or suggestions about my Privacy Policy,
              do not hesitate to contact me at +91 98252 11465.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
