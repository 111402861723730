import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { API } from "../../../API";
import pdf from "../../../Assets/pdf.png";
import CustomLoader from "../../customLoader/customLoader";
import CustomHeader from "../../title";

const Catalogue = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const controler = new AbortController();
    window.scrollTo(0, 0);
    getCatalogue(controler);
    return () => controler.abort();
  }, []);

  const getCatalogue = async (controler) => {
    try {
      const response = await fetch(API.catelogue, { signal: controler.signal });
      const data = await response.json();

      // console.log(data);
      setData(data[0]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fileUrl = `${API.PDF_URL}pdf/${data.pdfFile}`;
  return (
    <>
      <Helmet>
        <title>Argil || Catalogue
        </title>
        <link rel="canonical" href="https://www.argiltiles.com/catelogue"></link>

      </Helmet>
      <CustomHeader name="Catalogue" />
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pdf-heading">{data.title}</div>
              {/* <div className="pdf-post">Posted on : {data.created_at}</div> */}
              <div onClick={() => window.open(fileUrl)}>
                <img src={pdf} alt="" className="pdf-img" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Catalogue;
