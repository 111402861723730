import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CustomHeader from "../../../title";
import { API } from "../../../../API";
import CustomLoader from "../../../customLoader/customLoader";
import { Helmet } from "react-helmet";
import homeImg3 from "../../../../Assets/top-image.jpg";
import homeImg4 from "../../../../Assets/overview.jpg";
import homeImg5 from "../../../../Assets/process.jpg";
import homeImg6 from "../../../../Assets/contact.jpg";

import icon1 from "../../../../Assets/icon-mix.png";
import icon2 from "../../../../Assets/mix-processing.png";
import icon3 from "../../../../Assets/icon-polish.png";
import icon4 from "../../../../Assets/icon-quality.png";

import bacteria from "../../../../Assets/enhance/bacteria_free.png";
import environemnt from "../../../../Assets/enhance/environment.png";
import longer from "../../../../Assets/enhance/longer.png";
import more_durable from "../../../../Assets/enhance/more_durable.png";
import more_uniform from "../../../../Assets/enhance/more_uniform.png";
import required_less from "../../../../Assets/enhance/required_less.png";

import "./style.css";
import Enhance from "../../Home/enahnce";
import ChooseUs from "../../Home/chooseUs";

const QuartzStoneLab = () => {
  const initialValues = {
    name: "",
    email: "",
    contact: "",
    message: "",
  };

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const navigateQuartzInquery = (val) => {
    navigate("/quartzinquiry", { state: { data: val } });
  };

  const Controler = new AbortController();
  useEffect(() => {
    window.scrollTo(0, 0);
    getQuartzData(Controler);
    return () => {
      Controler.abort();
    };
  }, []);

  const getQuartzData = async (Controler) => {
    try {
      const response = await fetch(API.quartzProduct, {
        signal: Controler.signal,
      });
      const data = await response.json();
      // console.log(data);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  function refreshPage() {
    setData([]);
    getQuartzData(Controler);
    setIsLoading(true);
  }

  const [values, setValues] = useState(initialValues);

  let name, value;
  const handleInputChange = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  function handleSubmit(event) {
    event.preventDefault();
    setValues(initialValues);
    fetch(API.contactUs, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        contactno: data.contact,
        message: data.message,
      }),
    });
    setTimeout(() => {
      navigate("/thanks");
    }, 500);
  }

  return (
    <div>
      <Helmet>
        <title>
          Quartz Stone Slab For Kitchen Platform Countertops Manufacturers In
          Morbi, Gujarat, India | Argil
        </title>
        <link
          rel="canonical"
          href="https://www.argiltiles.com/quartzsurface"
        ></link>

        {/* OG Meta Tags */}

        <meta
          property="og:title"
          content="Quartz Stone Slab For Kitchen Platform Countertops Manufacturers In Morbi, Gujarat, India | Argil"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Argiltiles" />
        <meta
          property="og:url"
          content="https://www.argiltiles.com/quartzsurface"
        />
        <meta
          property="og:description"
          content="Argil is a quartz surface stone slab for kitchen platform countertops manufacturers in Morbi, Gujarat, India. We have the best engineered  quartz surface stone for kitchen and platform  at a reasonable price We have 10+ yrs of service. Get a price quote "
        />
        <meta property="og:image" content="Image URL" />

        {/* Twitter Meta Tags  */}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@argiltiles" />
        <meta
          property="twitter:url"
          content="https://www.argiltiles.com/quartzsurface"
        />
        <meta
          name="twitter:title"
          content="Quartz Stone Slab For Kitchen Platform Countertops Manufacturers In Morbi, Gujarat, India | Argil"
        />
        <meta
          name="twitter:description"
          content="Argil is a quartz surface stone slab for kitchen platform countertops manufacturers in Morbi, Gujarat, India. We have the best engineered  quartz surface stone for kitchen and platform  at a reasonable price We have 10+ yrs of service. Get a price quote  "
        />
        <meta name="twitter:image" content="Image URL" />
      </Helmet>
      <CustomHeader name="Quartz Surface" click={refreshPage} />

      {/* Quartz Stone Slab */}
      <div
        className="quartz-stone-pointer"
        onClick={() => {
          navigate("/contact");
        }}
      >
        <img src={homeImg3} className="w-100" />
      </div>

      {/* Overview Tab */}
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="p-5">
              <img src={homeImg4} className="w-100" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="quartz-overview p-5">
              <h2>overview</h2>
              <p>
                Composite or engineered quartz is a very solid material that
                resembles real granite in both look and utility. Engineered
                quartz is unaffected by stains, scratches, and cracks. It is
                also resistant to heat and cold. Engineered By blending quartz
                or granite aggregates with resins and pigments, man-made
                products like stone or quartz are created by quartz
                manufacturers in India, which are mostly generated from natural
                materials. The composition of engineered quartz slabs is
                typically 90% pulverised natural quartz and 10% polyresin.
                However, these percentages may vary slightly depending on the
                kind of quartz used.
              </p>

              {/* <div className="d-flex">
                <div
                  className=""
                  style={{
                    borderColor: "#CAC3BB",
                    borderWidth: 1,
                    borderStyle: "solid",
                    marginRight: "4%",
                  }}
                >
                  <NavLink
                    className="nav-link"
                    to="/contact"
                    style={{
                      color: "#CAC3BB",
                    }}
                  >
                    Get a Price Quote
                  </NavLink>
                </div>
                <div
                  className=""
                  style={{
                    borderColor: "#CAC3BB",
                    borderWidth: 1,
                    borderStyle: "solid",
                    paddingLeft: "3%",
                  }}
                >
                  <NavLink
                    className="nav-link"
                    to="/contact"
                    style={{
                      color: "#CAC3BB",
                    }}
                  >
                    Download Brochure
                  </NavLink>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* why choose argil groups section */}
      <ChooseUs />

      {/*  listing stone menus */}
      <div className="container">
        <div className="row">
          {isLoading ? (
            <CustomLoader />
          ) : (
            <>
              <h1 className="display-6 py-4" style={{ color: "#CAC3BB " }}>
                Quartz Stone Slab
              </h1>
              {data.map((data) => {
                let quartzImg = `${API.IMAGE_URL}quartz/${data.mainImg}`;
                return (
                  <div className="col-lg-4 col-md-6" key={data.id}>
                    <div
                      className="wallItemsImagBox"
                      onClick={() => navigateQuartzInquery(data)}
                    >
                      <div className="hoverImagesQuartz">
                        <div className="iconAlignMent">
                          {/* <div className="imageTitle">{data.name}</div> */}
                        </div>
                      </div>
                      <img
                        src={quartzImg}
                        alt=""
                        className="quartzItemsImages"
                      />

                      <div className="productMobileView">
                        {/* <div className="imageTitle">{data.name}</div> */}
                      </div>
                    </div>
                    <div className="imageTitle-quartz">{data.name}</div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="QuartzSurButtonAlign">
              {/* <div className="QuartzSurButton">
                <CustomButton ButtonName="Next" />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/*  enhance section */}
      <Enhance />

      {/* advantage section */}
      <div>
        <div className="container">
          <div className="mt-5 mb-5">
            <h3 className="text-center p-3" style={{ color: "#CAC3BB" }}>
              Advantages of Artifical Quartz Stones
            </h3>
            <p className="text-center" style={{ color: "white" }}>
              Artificial quartz stones are manufactured from natural quartz
              crystals that are bound together with the help of a binding resin,
              as compared with natural quartz stones, which are carved from pure
              sandstone, marble, or granite. Artificial variations will resemble
              the natural ones in appearance. However, there are numerous
              advantages to buying premium-class artificial quartz stone from
              artificial quartz stone manufacturers in India, which are
            </p>
          </div>
        </div>

        <div className="container">
          <div className="p-4" style={{ backgroundColor: "#3b3c3d" }}>
            <div style={{ color: "white", fontWeight: "bold", fontSize: 22 }}>
              Attractive look and feel
            </div>
            <p style={{ color: "white" }}>
              The compressed artificial quartz material is made into slabs with
              variations of natural stone's texture and colour. The manufactured
              varieties' colours differ in that they are much more uniform in
              appearance. You may get a wide variety of imitation quartz stones
              at different stores in lovely colours to match any decor.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="p-4" style={{ backgroundColor: "#1f1f1f" }}>
            <div style={{ color: "white", fontWeight: "bold", fontSize: 22 }}>
              Easy to maintain
            </div>
            <p style={{ color: "white" }}>
              Natural quartz has pores in them. However, the polished surface of
              artificial stone does not absorb moisture. As a result, cleaning
              the surface is simpler and requires less care. Additionally, the
              surface is stain- and scratch-resistant. Because of easy
              maintenance, these artificial quartz last longer and look new for
              years.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="p-4" style={{ backgroundColor: "#3b3c3d" }}>
            <div style={{ color: "white", fontWeight: "bold", fontSize: 22 }}>
              Available in a variety of options
            </div>
            <p style={{ color: "white" }}>
              The size, quart colours, treatments, and patterns of composite
              quartz stone are present in a variety of options. As a result, you
              will never be without resources to help in the design or
              renovation of an existing area. The top choices include Zodiac
              Black, Zodiac Red, Crystal White, and many others.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="p-4" style={{ backgroundColor: "#1f1f1f" }}>
            <div style={{ color: "white", fontWeight: "bold", fontSize: 22 }}>
              Cost effective
            </div>
            <p style={{ color: "white" }}>
              The cost of natural stone might vary depending on the kind,
              colour, and quality of the stone but is typically more. For less
              money, artificial quartz stone offers a more luxurious and
              traditional appearance. Quartz slab manufacturers in India make
              variety of cost effective slabs which are available in amazing
              colors and variants
            </p>
          </div>
        </div>
        <div className="container">
          <div className="p-4" style={{ backgroundColor: "#3b3c3d" }}>
            <div style={{ color: "white", fontWeight: "bold", fontSize: 22 }}>
              Durability
            </div>
            <p style={{ color: "white" }}>
              Artificial quartz is as strong and hard as granite, with the
              benefit of being chip and crack resistant. The engineered stone
              will only crack if it is not fitted properly on the surfaces.
              Hence, artificial quartz stones last longer and due to their easy
              maintenance, they look the same for years.
            </p>
          </div>
        </div>

        <div className="container">
          <div className="mt-5 mb-5">
            <h3 className="text-center p-3" style={{ color: "#CAC3BB" }}>
              Uses and Applications of Quartz Stones
            </h3>
            <p
              className="text-center"
              style={{ color: "white", marginBottom: "2%" }}
            >
              There are a number of usage and applications of artificial quartz
              stones which is why they are so much popular amongst people. These
              are
            </p>
            <ul style={{ color: "white" }}>
              <li>
                It can be perfect for both residential and commercial uses
                because of its long lifespan and low maintenance requirements.
                Quartz will provide your interior spaces with the ideal classy
                finish.
              </li>
              <li>
                You may also use engineered stone for your kitchen backsplash to
                prevent spills and stains on your walls.
              </li>
              <li>
                Engineered quartz slabs are ideal for interior walls since they
                are non-porous and frequently offer mould and mildew resistance.
                They are therefore used as quartz stone for kitchen platform and
                also appropriate for wet rooms in both residential and
                commercial structures.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Argil QUARTS slab manufacturing */}
      <div className="container-fluid bg-image-enhance">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="p-5">
                <img src={homeImg5} className="w-100" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="quartz-overview">
                <h2>Argil Quartz Slab Manufacturing Process</h2>
                <p>
                  Finely crushed quartz is combined with resin, stabilising
                  polymers, and colouring pigments to create Argil Quartz Slabs,
                  which are then polished to an amazing mirror finish. We are
                  one of the leading quartz countertops manufacturers in the
                  country with a number of satisfied customers.
                </p>
                <p>
                  At Argil, we have the greatest expectations for ourselves. To
                  ensure the final product is the finest it can be, our
                  production process follows a number of strict practices and
                  procedures. Mixing, pressing, polishing and quality control
                  are some of the best practices that are followed at our
                  facility
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-sm-3 text-center mb-2">
            <div
              style={{
                border: "2px solid #CAC3BB",
                padding: "10%",
                marginRight: "2%",
                height: "100%",
              }}
            >
              <img
                className="img-fluid"
                width={"40%"}
                src={icon1}
                alt=""
                srcset=""
              />
              <h6 className="py-4 text-center text-white">
                <div>MIXING</div>
              </h6>
              <div style={{ color: "white" }}>
                The fundamental components of an Argil Tiles Slab, such as
                Natural Quartz Grits, Resin, colouring agents (traces), and
                texturizing material, are all thoroughly tested before being
                combined in huge overhead mixers. It's crucial to remember that
                all Argil Tile variations and colours are produced using the
                same fundamental process which makes Argil, one of the leading
                quartz stone manufacturers in Morbi
              </div>
            </div>
          </div>
          <div className="col-sm-3 text-center mb-2">
            <div
              style={{
                border: "2px solid #CAC3BB",
                padding: "10%",
                marginRight: "2%",
                height: "100%",
              }}
            >
              <img
                className="img-fluid"
                width={"40%"}
                src={icon2}
                alt=""
                srcset=""
              />
              <h6 className="py-4 text-center text-white">
                <div>PRESSING</div>
              </h6>
              <div style={{ color: "white" }}>
                Spread onto trays, the mixture is compacted with intense
                vibration and pressure. The compressed mixture is then
                transferred to a curing room, where it is heated for about an
                hour at about 90°C before cooling. In this phase, the mixture's
                resin is activated, combining with the quartz to create a slab.
                These curved and irregular stones now have the classic hardness
                and stain resistance.
              </div>
            </div>
          </div>
          <div className="col-sm-3 text-center mb-2">
            <div
              style={{
                border: "2px solid #CAC3BB",
                padding: "10%",
                marginRight: "2%",
                height: "100%",
              }}
            >
              <img
                className="img-fluid"
                width={"40%"}
                src={icon3}
                alt=""
                srcset=""
              />
              <h6 className="py-4 text-center text-white">
                <div>POLISHING</div>
              </h6>
              <div style={{ color: "white" }}>
                Using two sets of parallel diamond saws, the uneven slab's four
                sides are now cut to the appropriate size. To achieve the
                precise needed thickness, the slab's upper and lower surfaces
                are calibrated using a multi-head diamond calibration machine.
                And a multi-head polishing machine is used to polish the
                finished surface until it has the correct mirror glaze. What
                distinguishes the finished product is its mirror glaze.
              </div>
            </div>
          </div>
          <div className="col-sm-3 text-center mb-2">
            <div
              style={{
                border: "2px solid #CAC3BB",
                padding: "10%",
                marginRight: "2%",
                height: "100%",
              }}
            >
              <img
                className="img-fluid"
                width={"40%"}
                src={icon4}
                alt=""
                srcset=""
              />
              <h6 className="py-4 text-center text-white">
                <div>QUALITY CONTROL</div>
              </h6>
              <div style={{ color: "white" }}>
                Every step of the manufacturing process undergoes inspection at
                Argil Tiles, from selecting the best raw materials to
                maintaining strict quality control standards. Our Quality
                Control division inspects each slab for flaws and impurities.
                The slabs are only sorted and marked for entry into our
                distribution system after they have given their permission.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="container">
        <h3 className="text-center mt-5 mb-3" style={{ color: "#CAC3BB" }}>
          FAQs
        </h3>

        <>
          <div className="accordion customAccordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What are quartz countertops?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="custom-body accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Quartz countertops are elegant, practical, and low-maintenance
                  and are composed of bits of quartz held together in an
                  industrial-grade binder. Quartz countertops manufacturers make
                  different varieties of quartz countertops to cater the needs
                  of their customers.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What are the advantages of quartz slabs?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="custom-body accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Attractive look and feel, Ease of to maintenance, Availability
                  in a variety of options, Cost effectiveness, and Durability
                  are some of the major advantages of quartz slabs{" "}
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What are the disadvantages of quartz slabs?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="custom-body accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  The cost and heat resistance of quartz countertops are their
                  major disadvantages. A quartz kitchen counter top can be out
                  of your price range if you're installing worktops on a tight
                  budget. Additionally, heated cookware and appliances can harm
                  quartz countertops.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  How can quartz slabs be used?
                </button>
              </h2>
              <div
                id="collapseFour"
                className="custom-body accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Quartz slabs are perfect for a variety of wonderful home
                  improvements. Quartz is most frequently used for countertops,
                  backsplashes in kitchens, tabletop surfaces, and flooring.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Are quartz slabs high maintenance?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="custom-body accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Low maintenance is required for quartz slabs and countertops.
                  Look no further if you're unsure of how to maintain your black
                  or white quartz countertops. Quartz is relatively simple to
                  keep clean and maintain. To keep surfaces looking their best,
                  use a soft substance cloth and a moderate cleaning chemical.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How much is a slab of quartz?
                </button>
              </h2>
              <div
                id="collapseSix"
                className="custom-body accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Quartz slabs are significantly more expensive per square than
                  materials like ceramic and porcelain. Quartz slabs of good
                  quality and depending upon the thickness artificial quartz
                  stone manufacturers in Morbi and rest of India can charge
                  between 200 to 400 Rupees per square foot.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSeven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Do quartz countertops scratch easily?
                </button>
              </h2>
              <div
                id="collapseSeven"
                className="custom-body accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Quartz slabs and countertops are extremely scratch-resistant;
                  quartz of good quality may withstand regular use without being
                  harmed.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingEight">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Is quartz more expensive than granite?
                </button>
              </h2>
              <div
                id="collapseEight"
                className="custom-body accordion-collapse collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Granite costs more than quartz often. There are several types
                  of low-quality granite that you might get cheaply, but using
                  such a material is not advised.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingNine">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  Is quartz better than granite?
                </button>
              </h2>
              <div
                id="collapseNine"
                className="custom-body accordion-collapse collapse"
                aria-labelledby="headingNine"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Both quartz and granite are extraordinarily stunning and
                  durable. Depending on your preferred design and intended use
                  for your countertops or floors, you may select one material
                  over another. Granite is a natural stone, while quartz is
                  man-made. Compared to granite, quartz requires fewer
                  maintenance tasks because it doesn't need to be sealed.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTen">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  Does quartz stain easily?
                </button>
              </h2>
              <div
                id="collapseTen"
                className="custom-body accordion-collapse collapse"
                aria-labelledby="headingTen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Quartz is not easily stained. Quartz tiles and slabs are
                  stain-resistant because of the resin combination used in their
                  construction.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading11">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse11"
                  aria-expanded="false"
                  aria-controls="collapse11"
                >
                  Is quartz cheaper than concrete?
                </button>
              </h2>
              <div
                id="collapse11"
                className="custom-body accordion-collapse collapse"
                aria-labelledby="heading11"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Concrete countertops are typically less expensive for
                  homeowners than quartz surfaces. Quartz countertops are more
                  expensive but require little maintenance, making them the more
                  preferred choice.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading12">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse12"
                  aria-expanded="false"
                  aria-controls="collapse12"
                >
                  Is quartz cheaper than marble?
                </button>
              </h2>
              <div
                id="collapse12"
                className="custom-body accordion-collapse collapse"
                aria-labelledby="heading12"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Yes. Quartz is frequently less expensive than marble. Natural
                  luxury stones like marble are typically fairly pricey. Quartz
                  countertops can be great for a luxurious aesthetic with a
                  lower price tag because they often cost substantially less.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading13">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse13"
                  aria-expanded="false"
                  aria-controls="collapse13"
                >
                  How should quartz be cleaned?
                </button>
              </h2>
              <div
                id="collapse13"
                className="custom-body accordion-collapse collapse"
                aria-labelledby="heading13"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  The best cleaner for quartz slabs or countertops is a blend of
                  warm water and mild detergent, despite the fact that there are
                  numerous secure commercial products on the market. Your quartz
                  surfaces will remain clean with routine cleaning.
                </div>
              </div>
            </div>
          </div>
        </>
      </div>

      {/* Contact Us */}
      <div className="mt-5 mb-5">
        <div className="container">
          <div className="section-contact-us">
            <img
              className="img-fluid contact-us-img"
              src={homeImg6}
              alt=""
              srcset=""
            />
            <div className="contact-us-box container">
              <h3>Contact us</h3>
              <div>Let's Be in Touch !!!</div>
              <div className="mt-3">
                <form onSubmit={handleSubmit}>
                  <div className="row input-container">
                    <div className="col-md-6">
                      <span className="text-white mt-5">Name</span>
                      <div className="styled-input wide m-0 p-0">
                        <input
                          type="text"
                          onChange={handleInputChange}
                          value={values.name}
                          name="name"
                          required
                        />
                        <label htmlFor="">Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <span className="text-white mt-5">Email</span>
                      <div className="styled-input wide m-0 p-0">
                        <input
                          type="email"
                          onChange={handleInputChange}
                          value={values.email}
                          name="email"
                          required
                        />
                        <label>Email</label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <span className="text-white mt-5">Phone</span>
                      <div className="styled-input wide m-0 p-0">
                        <input
                          type="number"
                          onChange={handleInputChange}
                          value={values.contact}
                          name="contact"
                          required
                        />
                        <label>Contact Number</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <span className="text-white mt-5">Subject</span>
                      <div className="styled-input wide m-0 p-0">
                        <input type="text" name="Subject" required />
                        <label htmlFor="">Subject</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <span className="text-white mt-5">Description</span>
                      <div className="styled-input w-100 m-0 p-0">
                        <textarea
                          onChange={handleInputChange}
                          value={values.message}
                          name="message"
                          required
                        ></textarea>
                        <label>Message</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="btn-lrg submit-btn">
                        Request Price Quote
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuartzStoneLab;
